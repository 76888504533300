import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import NewFAQ from 'components/common/FAQs/NewFAQ';
import BasicPlan from 'components/subscriptions/basicPlan';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { billingFaqOptions as sellerFaqs } from 'components/common/FAQs/sellerData';
import { billingFaqOptions as buyerFaqs } from 'components/common/FAQs/buyerData';

import { useTitle } from 'utils/router';

export default function Subscriptions() {
  useTitle('My Subscription');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const authContext = useContext(AuthContext);
  return (
    <Box style={{ backgroundColor: '#F9FAFB', paddingTop: isMobileScreen ? '32px' : '56px' }}>
      <Container maxWidth="lg">
        <Box maxWidth={'1032px'} margin={'auto'}>
          <Typography variant={isMobileScreen ? 'h5' : 'h4'}>Your Subscription</Typography>
          <BasicPlan />
        </Box>
      </Container>
      <NewFAQ
        description="Everything you need to know about billing."
        options={authContext.user?.isSellingServices ? sellerFaqs : buyerFaqs}
      />
    </Box>
  );
}
