import { IChatMessageDisplay } from 'global/interfaces/chatMessage';

import Typography from '@mui/material/Typography';
import { ContractPaymentSecretType, ContractType, IContractPaymentSecret } from 'global/interfaces/contract';
import { getCurrencySymbol } from 'components/common/Select/CountrySelect';
import React from 'react';
import { Box, Dialog, DialogContentText, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import { LoadingButton } from '@mui/lab';
import useProgressBar from 'global/hooks/useProgressBar';
import { getContractRetainerPaymentSecret } from 'services/contractService';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { RoundButton } from 'components/common/Button/RoundButton';

interface IChatMessagePaymentProps extends IChatMessageDisplay {
  currentUserId?: string;
}

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '12px 18px',
  borderRadius: theme.spacing(6),
  height: '35px',
}));

export default function ChatMessagePaymentFailed(props: IChatMessagePaymentProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [secret, setSecret] = React.useState<IContractPaymentSecret | undefined>(undefined);

  const [progress, showProgress] = useProgressBar();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const isSeller = props.currentUserId !== props.contract?.buyerUserId;
  const currencySymbol: string = getCurrencySymbol(props.contract?.currency);

  const handleUpdatePaymentClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    updatePaymentDetails();
  };

  const handleDialogClose = (): void => {
    setDialogOpen(false);
  };

  const updatePaymentDetails = (): void => {
    showProgress(true);
    getContractRetainerPaymentSecret(props.contract?.contractId ?? '')
      .then((res: IContractPaymentSecret) => {
        if (res.type === ContractPaymentSecretType.Url) {
          window.location.href = res.value;
        } else {
          showProgress(false);
          setSecret(res);
          setDialogOpen(true);
        }
      })
      .catch((err: IApiError) => {
        showProgress(false);
        showError(err);
      });
  };
  return (
    <>
      {props.contract?.contractType == ContractType.Retainer && (
        <Typography variant="body2" color="grey.900">
          {props.contract?.recurrence} payment of {currencySymbol}
          {props.contract?.amount} has failed for the retainer contract &quot;{props.contract?.name}&quot;.{' '}
          {props.contract?.buyerUserId === props.currentUserId &&
            ' Please update your payment details to avoid contract termination.'}
        </Typography>
      )}
      {isSeller && props.contract?.contractType == ContractType.Retainer && (
        <Typography variant="caption" color="grey.500" fontStyle="italic" fontWeight={600}>
          We have notified the buyer. The contract will be terminated in 1 week if payment is not received.
        </Typography>
      )}
      {props.contract?.buyerUserId === props.currentUserId && props.contract !== undefined && !props.actioned && (
        <React.Fragment>
          <Box>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" mt="8px">
              <Grid item xs={12} sm={6} md={12} lg={5}>
                <StyledLoadingButton
                  variant="contained"
                  type="button"
                  fullWidth
                  size="small"
                  onClick={e => handleUpdatePaymentClick(e)}
                  startIcon={<DoneAllOutlinedIcon />}
                >
                  Update Payment Details
                </StyledLoadingButton>
              </Grid>
            </Grid>
          </Box>

          <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="xs" fullScreen={isMobileScreen}>
            {secret?.type === ContractPaymentSecretType.Cancelled && (
              <React.Fragment>
                <StyledDialogTitle>
                  <Typography variant="h6" color="grey.900">
                    Contract Terminated
                  </Typography>
                </StyledDialogTitle>
                <StyledDialogContent dividers>
                  <DialogContentText>
                    <Typography variant="body1">{`Contract ${props.contract.name} has been terminated and no longer requires updated payment details.`}</Typography>
                  </DialogContentText>
                </StyledDialogContent>
                <StyledDialogActions>
                  <RoundButton variant="contained" onClick={handleDialogClose} autoFocus>
                    Got It
                  </RoundButton>
                </StyledDialogActions>
              </React.Fragment>
            )}
          </Dialog>
        </React.Fragment>
      )}
      {props.contract?.buyerUserId === props.currentUserId && props.actioned && (
        <Typography variant="caption" color="grey.500" fontStyle="italic" fontWeight={600}>
          You have already updated your payment details.
        </Typography>
      )}
      {progress}
    </>
  );
}
