import { useTitle } from 'utils/router';

import { Box, Container, Stack, Typography } from '@mui/material';

import { LPSectionLI, LPSectionUl } from 'components/info/LPSection';
import { StyledCTAButtton } from 'components/common/Button/CTAs';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';

export default function Vid() {
  useTitle('Shoutt | Connecting Founders and Freelancers');
  const authContext = useContext(AuthContext);

  const navigate = useNavigate();
  const handleButtonClick = (): void => {
    navigate('/signup');
  };
  return (
    <>
      <Box sx={{ backgroundColor: '#F9FAFB' }}>
        <Container maxWidth="md">
          <Box sx={{ display: 'flex', justifyContent: 'center' }} pt={{ xs: 5, md: 8 }} pb={{ xs: 5, md: 8 }}>
            <Stack direction={'column'} spacing={{ xs: 4, md: 6 }} maxWidth={'1032px'}>
              <Typography variant="h5" fontSize={{ xs: '22px', md: '32px' }}>
                Learn why we created Shoutt and how it can help grow your freelancer business in 5 mins
              </Typography>
              <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                <iframe
                  src="https://www.loom.com/embed/74b263e98725417e95adecf36d7ba34f?sid=721f3606-6b10-4bad-8958-6f6c9538ad3e"
                  frameBorder={0}
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                ></iframe>
              </div>
              <Stack sx={{ display: 'flex', justifyContent: 'center' }} direction={'column'}>
                <Typography variant="h6">In a rush? Here’s the key points on why you should be on Shoutt!</Typography>
                <LPSectionUl>
                  <LPSectionLI>No commissions for buyers or sellers - keep 100% of your earnings</LPSectionLI>
                  <LPSectionLI>Vetted talent only which in turn attract top-tier clients</LPSectionLI>
                  <LPSectionLI>
                    Bring your existing clients to streamline contracts, get reviews and avoid late/non payment
                  </LPSectionLI>
                  <LPSectionLI>No bidding wars—just create your profile and get discovered</LPSectionLI>
                </LPSectionUl>
                {!authContext.user && (
                  <Box mt={2}>
                    <StyledCTAButtton variant="contained" onClick={handleButtonClick} endIcon={<EastIcon />}>
                      Apply Now
                    </StyledCTAButtton>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
}
