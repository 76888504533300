import { countries, fallbackContractCountry, getCurrencyDetails } from 'components/common/Select/CountrySelect';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { convertToViewerCurrency } from 'utils/currency';

interface ILocalizationProps {
  localCurrencySymbol: string;
  rateInCurrency: number | undefined;
  currency: string | undefined;
}

export const useLocalization = (hourlyUsdRate: number | undefined): ILocalizationProps => {
  const [localCurrencySymbol, setLocalCurrencySymbol] = useState<string>('');
  const [rateInCurrency, setRateInCurrency] = useState<number>();
  const [currency, setCurrency] = useState<string>(() => fallbackContractCountry.currency);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (!authContext.viewerCountry) {
      return;
    }

    const viewerCountry =
      countries.find(c => c.code === authContext.viewerCountry) ??
      countries.find(c => c.code === fallbackContractCountry.code)!;

    const currencyItem = getCurrencyDetails(viewerCountry.currency ?? fallbackContractCountry.currency);
    setLocalCurrencySymbol(currencyItem?.currencySymbol);
    setCurrency(viewerCountry.currency ?? fallbackContractCountry.currency);

    if (hourlyUsdRate && viewerCountry?.currency && currencyItem.conversionRate) {
      const convertedRate: number = convertToViewerCurrency(hourlyUsdRate, viewerCountry.code);
      setRateInCurrency(convertedRate);
    } else {
      setRateInCurrency(hourlyUsdRate);
    }
  }, [authContext.viewerCountry]);

  return { localCurrencySymbol, rateInCurrency, currency };
};
