import { Dialog, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';
import { ContractType, RecurrenceType } from 'global/interfaces/contract';
import { AuthContext } from 'contexts/AuthContext';
import useProgressBar from 'global/hooks/useProgressBar';
import { ActivePlan } from './ActivePlanConfirmation';
import InactiveContractContent from './InactiveContractContent';
import { useSubscriptionSession } from 'global/hooks/useSubscription';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { useLocalization } from 'global/hooks/useLocalization';

export interface IConfirmContractDialogProps {
  showConfirmDialog: boolean;
  handleConfirmDialogClose: () => void;
  contractType: ContractType;
  firstPayment: boolean;
  recurrence?: RecurrenceType;
  isRequestInvoice: boolean;
  handleClick: () => void;
  chatThreadId: string;
}

export function ConfirmContractDialog(props: IConfirmContractDialogProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const authContext = useContext(AuthContext);
  const [progress, showProgress] = useProgressBar();
  const recurrenceText = props.recurrence === RecurrenceType.Monthly ? 'month' : 'week';

  const isSubscribed = authContext.user?.subscriptionActive;

  const { createSubscriptionSession } = useSubscriptionSession(showProgress);

  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);

  return (
    <Dialog
      open={props.showConfirmDialog}
      onClose={props.handleConfirmDialogClose}
      maxWidth="xs"
      fullScreen={isMobileScreen}
    >
      {props.contractType === ContractType.Retainer && (
        <>
          {isSubscribed ? (
            <ActivePlan
              {...props}
              contentText={
                <>
                  <Typography variant="body1">
                    {props.isRequestInvoice
                      ? `You will be emailed an invoice each ${recurrenceText}, which you can pay with via the seller's enabled payment methods. Payments should be made within 7 days in order to keep the contract running smoothly. The invoice will open in the current tab so you can pay it directly or download a PDF.`
                      : `Your card will be automatically debited each ${recurrenceText} until the end of the contract. You can cancel the contract at anytime to prevent further payments. Your funds will be held in escrow according to the agreed release schedule.`}
                  </Typography>
                  {!props.firstPayment && (
                    <Typography variant="body1">
                      {`The invoice will open in the current tab so you can pay it directly or download a PDF. You will also be emailed the invoice to your registered email address.`}
                    </Typography>
                  )}
                </>
              }
            />
          ) : (
            <InactiveContractContent
              upgradeFunc={() => createSubscriptionSession()}
              handleConfirmDialogClose={props.handleConfirmDialogClose}
              headingText="Upgrade to Premium"
              contentText={
                <>
                  <Typography variant="body2">
                    Upgrade to the premium plan to start creating retainer contracts.
                  </Typography>
                  <Typography variant="body2">
                    The Shoutt Premium plan costs {localCurrencySymbol}
                    {price} / month with no long term contract and a 30 day money back guarantee. We don’t charge any
                    commission on transactions.
                  </Typography>
                </>
              }
            />
          )}
        </>
      )}
      {props.contractType !== ContractType.Retainer && !isSubscribed && (
        <InactiveContractContent
          upgradeFunc={() => createSubscriptionSession()}
          handleConfirmDialogClose={props.handleConfirmDialogClose}
          headingText="Protect your payment"
          contentText={
            <Typography variant="body2">
              We recommend upgrading to premium for {localCurrencySymbol}
              {price} / month. With premium your payment will be protected so if there are issues with the seller not
              delivering then we can assist and refund your money if appropriate. You can continue without premium but
              your payment will not be protected.
            </Typography>
          }
          skipFunc={props.handleClick}
        />
      )}
      {progress}
    </Dialog>
  );
}
