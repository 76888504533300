import { authorizedPostForm } from './baseApiService';
import { nameof } from 'ts-simple-nameof';
import { IPartnerForm } from 'global/interfaces/partner';

const resource = 'Partner';

export async function sendPartnerDetails(partnerDetails: IPartnerForm) {
  const formData = new FormData();
  formData.append(
    nameof<IPartnerForm>(x => x.name),
    partnerDetails.name,
  );
  formData.append(
    nameof<IPartnerForm>(x => x.email),
    partnerDetails.email,
  );
  formData.append(
    nameof<IPartnerForm>(x => x.website),
    partnerDetails.website,
  );
  formData.append(
    nameof<IPartnerForm>(x => x.linkedin),
    partnerDetails.linkedin,
  );
  formData.append(
    nameof<IPartnerForm>(x => x.currency),
    partnerDetails.currency,
  );
  if (partnerDetails.userId) {
    formData.append(
      nameof<IPartnerForm>(x => x.userId),
      partnerDetails.userId,
    );
  }
  if (partnerDetails.token) {
    formData.append(
      nameof<IPartnerForm>(x => x.token),
      partnerDetails.token,
    );
  }

  return await authorizedPostForm(`${resource}`, formData);
}
