import concierge from 'assets/images/lps/concierge.webp';

import reviews from 'assets/images/lps/reviewsandreputation.webp';
import LPSection, {
  LPSectionLI,
  LPSectionOl,
  LPSectionTypography,
  LPSectionUl,
  LPTextProps,
} from 'components/info/LPSection';

import { Box, Grid, styled, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import { useLocalization } from 'global/hooks/useLocalization';
import paymentmethods from 'assets/images/lps/paymentmethods.svg';
import payments from 'assets/images/lps/contractsandinvoices.svg';
import { StyledCTAButtton } from 'components/common/Button/CTAs';
import { getConciergePricing } from 'utils/currency';

const StyledReviewBox = styled(Box)(() => ({
  backgroundColor: '#FD774A',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#FD8D68',
  borderRadius: '12px',
  padding: '12px 24px 12px 24px',
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  minHeight: '74px',
}));

const ReviewBoxTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'darkMode',
})<LPTextProps>(({ darkMode, theme }) => ({
  color: darkMode ? theme.palette.common.white : theme.palette.grey[600],
  fontSize: '20px',

  [theme.breakpoints.down('lg')]: {
    fontSize: '16px',
  },
}));

export default function BuyerMainPoints() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { localCurrencySymbol } = useLocalization(29.99);
  const concPrice = getConciergePricing();

  const handleButtonClick = (): void => {
    navigate('/concierge');
  };
  return (
    <>
      {concPrice > 0 && (
        <LPSection
          image={concierge}
          backgroundColor="#F9FAFB"
          title="Submit your brief and get 3 proposals"
          minHeight="800px"
        >
          <>
            <LPSectionTypography>
              No time to browse our{' '}
              <span
                style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
                onClick={() => navigate('/talent')}
              >
                vetted network
              </span>{' '}
              yourself?
              <br />{' '}
              <span style={{ fontWeight: 600 }}>
                We’ll source 3 proposals for just {localCurrencySymbol}
                {getConciergePricing()}.
              </span>
              <br />
              <LPSectionOl>
                <LPSectionLI>Submit your project brief to us</LPSectionLI>
                <LPSectionLI>We’ll scour our network (and beyond) to find 3 perfect matches</LPSectionLI>
                <LPSectionLI>Satisfaction guaranteed, or we’ll give you a full refund</LPSectionLI>
              </LPSectionOl>
            </LPSectionTypography>
            <Box>
              <StyledCTAButtton variant="contained" onClick={handleButtonClick} endIcon={<EastIcon />}>
                Get 3 Proposals
              </StyledCTAButtton>
            </Box>
          </>
        </LPSection>
      )}
      {concPrice <= 0 && (
        <LPSection
          image={concierge}
          backgroundColor="#F9FAFB"
          title="Submit your brief and get 3 proposals"
          minHeight="800px"
        >
          <>
            <LPSectionTypography>
              No time to browse our{' '}
              <span
                style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
                onClick={() => navigate('/talent')}
              >
                vetted network
              </span>{' '}
              yourself?
              <br /> <span style={{ fontWeight: 600 }}>We’ll source 3 proposals for FREE.</span>
              <br />
              <LPSectionOl>
                <LPSectionLI>
                  Submit your project brief to us. We can help refine your brief and scope the project.
                </LPSectionLI>
                <LPSectionLI>We’ll scour our network (and beyond) to find 3 perfect matches</LPSectionLI>
                <LPSectionLI>You choose one and pay on the platform, without any obligations</LPSectionLI>
              </LPSectionOl>
            </LPSectionTypography>
            <Box>
              <StyledCTAButtton variant="contained" onClick={handleButtonClick} endIcon={<EastIcon />}>
                Get 3 Proposals
              </StyledCTAButtton>
            </Box>
          </>
        </LPSection>
      )}
      <LPSection
        backgroundColor="#F0F4FE"
        image={payments}
        chipText="INVOICE & CONTRACTS"
        title="Protect your funds when working with freelancers"
        imageOnRight={true}
      >
        <>
          <LPSectionTypography>
            Choose a connection or invite a freelancer to Shoutt and pay freelancers across the globe with secure
            payment protection.
            <LPSectionUl>
              <LPSectionLI>Your funds are held in secure escrow until you approve the work</LPSectionLI>
              <LPSectionLI>Pay invoices seamlessly with a variety of methods</LPSectionLI>
              <LPSectionLI>100% commission free, supports retainers and fixed price projects</LPSectionLI>
            </LPSectionUl>
            <img src={paymentmethods} loading="lazy" style={{ width: '90%' }} />
          </LPSectionTypography>
        </>
      </LPSection>
      <LPSection
        background="linear-gradient(180deg, #172155 0%, #0D1330 100%)"
        image={reviews}
        chipText="REVIEWS"
        title="Boost your reputation as a great client"
        minHeight="700px"
        imageOnRight={true}
        darkMode={true}
        extra={
          <Grid container spacing={3} mt={3} mb={3}>
            <Grid item xs={12} md={4}>
              <StyledReviewBox>
                <ReviewBoxTypography darkMode={true} fontWeight={500}>
                  Invite your freelancers to Shoutt
                </ReviewBoxTypography>
              </StyledReviewBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledReviewBox>
                <ReviewBoxTypography darkMode={true} fontWeight={500}>
                  Pay them (100% commission free) on Shoutt and get reviews
                </ReviewBoxTypography>
              </StyledReviewBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledReviewBox>
                <ReviewBoxTypography darkMode={true} fontWeight={500}>
                  Attract the best talent to help grow your business!
                </ReviewBoxTypography>
              </StyledReviewBox>
            </Grid>
          </Grid>
        }
      >
        <>
          <LPSectionTypography darkMode={true}>
            The best freelancers want to work with the best clients. Work with all your freelancers on Shoutt to gain
            reviews easily and build your reputation.
          </LPSectionTypography>
        </>
      </LPSection>
    </>
  );
}
