import {
  Box,
  Container,
  Dialog,
  DialogContent,
  FormHelperText,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'contexts/AuthContext';
import logo from 'assets/images/logo/Logo.svg';
import { Link, useSearchParams } from 'react-router-dom';
import PricingTable from 'components/common/Subscription/PricingTable';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { billingFaqOptions as sellerFaqs } from 'components/common/FAQs/sellerData';
import { billingFaqOptions as buyerFaqs } from 'components/common/FAQs/buyerData';
import NewFAQ from 'components/common/FAQs/NewFAQ';
import { StyledCTAButtton } from 'components/common/Button/CTAs';
import DoneIcon from '@mui/icons-material/Done';
import EastIcon from '@mui/icons-material/East';
import { chooseFreePlan } from 'services/userService';
import { createProductCheckout } from 'services/subscriptionService';

const Logo = styled('img')(({ theme }) => ({
  height: theme.spacing(4.5),
  marginRight: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    height: theme.spacing(4),
  },
}));

enum PlanType {
  Free,
  Premium,
}

interface StyledCTAPlanProps {
  isSelected: boolean;
}

//top paddings are calculated offset for app bar
const StyledCTAPlanButtton = styled(StyledCTAButtton, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isSelected',
})<StyledCTAPlanProps>(({ theme, isSelected }) => ({
  mt: 2,
  width: '100%',
  backgroundColor: isSelected ? theme.palette.grey[400] : theme.palette.common.white,
  borderColor: isSelected ? theme.palette.green[400] : theme.palette.common.white,
  borderStyle: 'solid',
  borderWidth: '2px',
  color: theme.palette.common.black,
  '&:hover': {
    borderColor: isSelected ? theme.palette.grey[600] : theme.palette.grey[400],
    backgroundColor: isSelected ? theme.palette.grey[400] : theme.palette.grey[200],
  },
}));

export default function ChooseSubDialog(): JSX.Element {
  const authContext = useContext(AuthContext);
  const [open, setOpen] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [plan, setPlan] = useState<PlanType | undefined>(undefined);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (authContext.user?.mustChooseSubscription && !open && !searchParams.has('s')) {
      setPlan(undefined);
      setOpen(true);
    } else if (open && !authContext.user?.mustChooseSubscription) {
      setOpen(false);
    }
  }, [authContext.user?.mustChooseSubscription]);

  const handlePlanClick = (chosenPlan: PlanType): void => {
    setPlan(chosenPlan);
  };

  useEffect(() => {
    setError(plan === undefined);
  }, [plan]);

  const handleContinueClick = (): void => {
    setLoading(true);
    setSubmitted(true);
    setError(false);
    if (plan == PlanType.Free) {
      //JE: Not ideal. Done this way as the body was not scrollable on seller signup after choosing free plan
      chooseFreePlan().then(() => window.location.reload());
    } else if (plan == PlanType.Premium) {
      createProductCheckout(`${window.location.href}`)
        .then(res => {
          window.location.href = res;
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <Dialog open={open} fullScreen={true}>
      <DialogContent style={{ padding: 0, backgroundColor: '#F9FAFB' }}>
        <Container maxWidth="lg">
          <Stack alignItems="center" direction="row" sx={{ paddingTop: '24px', paddingBottom: '24px' }}>
            <Stack component={Link} to="/">
              <Logo alt="Shoutt" src={logo} />
            </Stack>
          </Stack>
          <Typography variant={isMobile ? 'h5' : 'h4'}>Choose a plan</Typography>
          <Typography variant={'body1'} fontSize={{ xs: '16px', md: '18px' }} mt={2}>
            Plans run month-to-month and can be upgraded/downgraded anytime. For more info on billing please scroll down
            to the FAQs.
          </Typography>
          <Box mt={6}>
            <PricingTable
              isSeller={authContext.user?.isSellingServices ?? false}
              currencySybmol={localCurrencySymbol}
              premPrice={price}
              freeBgColor="#e3c094"
              freeCTA={
                <>
                  <StyledCTAPlanButtton
                    variant="contained"
                    startIcon={plan == PlanType.Free ? <DoneIcon /> : undefined}
                    onClick={() => {
                      handlePlanClick(PlanType.Free);
                    }}
                    isSelected={plan == PlanType.Free}
                  >
                    {plan == PlanType.Free ? 'Plan Selected' : 'Select Plan'}
                  </StyledCTAPlanButtton>
                </>
              }
              premCTA={
                <>
                  <StyledCTAPlanButtton
                    variant="contained"
                    startIcon={plan == PlanType.Premium ? <DoneIcon /> : undefined}
                    isSelected={plan == PlanType.Premium}
                    onClick={() => {
                      handlePlanClick(PlanType.Premium);
                    }}
                  >
                    {plan == PlanType.Premium ? 'Plan Selected' : 'Select Plan'}
                  </StyledCTAPlanButtton>
                </>
              }
            />
            <Box
              sx={{
                display: 'flex',
                margin: 'auto',
                mt: 4,
                alignItems: 'flex-end',
                flexDirection: 'column',
                maxWidth: '1032px',
              }}
            >
              <FormHelperText>
                <Typography variant="body1" fontSize={{ xs: '16px', md: '18px' }} color={'#F04438'}>
                  {error && submitted ? 'Please select a plan to continue' : ''}
                </Typography>
              </FormHelperText>
              <StyledCTAButtton
                variant="contained"
                endIcon={<EastIcon />}
                onClick={handleContinueClick}
                sx={{ mt: 2 }}
                loading={loading}
              >
                Continue
              </StyledCTAButtton>
            </Box>
          </Box>
        </Container>
        <NewFAQ
          description="Everything you need to know about billing."
          options={authContext.user?.isSellingServices ? sellerFaqs : buyerFaqs}
        />
      </DialogContent>
    </Dialog>
  );
}
