import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { useTitle } from 'utils/router';
import { Box, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { IFrontUser } from 'global/interfaces/user';
import { getFrontUser } from 'services/userService';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import ProfileInfo from 'components/dashboard/ProfileInfo';
import MainActions from 'components/dashboard/MainActions';
import MainSection from 'components/dashboard/MainSection';
import VettingAlert from 'components/common/VettingAlert';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: '40px',

  [theme.breakpoints.down('md')]: {
    marginTop: '0px',
    padding: '0px',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: '0px',
  paddingBottom: '56px',
  [theme.breakpoints.down('md')]: {
    marginTop: '24px',
    paddingRight: '16px',
    paddingLeft: '16px',
    paddingBottom: '32px',
  },
}));

function Dashboard(): JSX.Element {
  useTitle('Shoutt | Connecting Founders and Freelancers');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const authContext = useContext(AuthContext);
  const [user, setUser] = useState<IFrontUser | null>(null);

  useEffect(() => {
    if (authContext.user?.id !== undefined && !authContext.loading) {
      getFrontUser(authContext.user?.id)
        .then((res: IFrontUser) => {
          setUser(res);
        })
        .catch((err: IApiError) => {
          showError(err);
        });
    }
  }, [authContext.user, authContext.loading]);

  return (
    <section>
      <StyledContainer maxWidth="lg">
        <VettingAlert sx={{ mb: !isMobile ? 3 : 0, mt: !isMobile ? -1 : 0 }} />

        <StyledBox>
          <Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between" rowGap={2}>
            <Typography variant={isMobile ? 'h5' : 'h4'}>Hi {authContext.user?.firstName}</Typography>

            {user !== null && <ProfileInfo user={user} />}
          </Stack>
          {user !== null && <MainActions />}
          {user !== null && <MainSection user={user} />}
        </StyledBox>
      </StyledContainer>
    </section>
  );
}

export default Dashboard;
