import { IPartnerForm } from 'global/interfaces/partner';
import { SchemaOf, object, string } from 'yup';

export const partnerValidationSchema = (shouldValidateToken: boolean): SchemaOf<IPartnerForm> => {
  const baseSchema = object({
    name: string()
      .required('Name is required')
      .trim()
      .min(2, 'Minimum of 2 characters needed')
      .max(30, 'Maximum of 30 characters allowed'),
    email: string()
      .required('Email is required')
      .trim()
      .max(200, 'Maximum of 200 characters allowed')
      .email('Invalid email address'),
    website: string().required('Website is required').trim().max(400, 'Maximum of 400 characters allowed'),
    linkedin: string().required('LinkedIn is required').trim().max(400, 'Maximum of 400 characters allowed'),
    currency: string().required('Currency is required'),
    userId: string().notRequired(),
  });

  if (shouldValidateToken) {
    return baseSchema.shape({
      token: string().required('You must complete the reCAPTCHA'),
    });
  }

  return baseSchema.shape({
    token: string().notRequired(),
  });
};
