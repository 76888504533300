import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ContractDeliverableStatus,
  ContractStatus,
  ContractType,
  ContractViewType,
  IContractDisplay,
  IDeliverable,
} from 'global/interfaces/contract';
import { IActiveContractsProps } from './rightMenu';
import React from 'react';
import { ChatType } from 'global/interfaces/chatThread';
import { dateStringFromDate } from 'utils/dateFormat';
import { updateContract } from 'services/contractService';
import { useCustomEventListener } from 'react-custom-events';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { AccessTime, Close, Tune } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { UserAvatar } from 'components/common/UserAvatar';

import { SwipeableContractDrawer } from 'components/common/Contract/SwipeableContractDrawer';
import { ResolveDisputeDrawer } from './resolveDisputeDrawer';
import { RoundButton } from 'components/common/Button/RoundButton';
import {
  DeliverableTypography,
  EllipsisTypography,
  EllipsisWrapperDiv,
  IndentSmallTypography,
} from 'components/common/StyledWorkroom/WorkroomStyles';
import { CheckDoneIcon } from 'components/icon/CheckDoneIcon';
import { ViewContractIcon } from 'components/icon/ViewContractIcon';
import { getCurrencySymbol } from 'components/common/Select/CountrySelect';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import { RepeatIcon } from 'components/icon/RepeatIcon';
import { FixedPriceIcon } from 'components/icon/FixedPriceIcon';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { ArrowBlockLeftIcon } from 'components/icon/ArrowBlockLeftIcon';
import { ArrowBlockRightIcon } from 'components/icon/ArrowBlockRightIcon';

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const DeliverablesFlexBox = styled(FlexBox)`
  align-items: flex-start;
`;

const DeliverableIconBox = styled(Box)`
  margin-top: 1px;
  height: 20px;
`;

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary[50],
  color: theme.palette.primary.main,
  width: '30px',
  height: '30px',
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  minHeight: '68px',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    height: 'fit-content',
    minHeight: '51px',
    paddingBottom: '8.5px',
    paddingTop: '11px',
    alignItems: 'center',
    marginBottom: '8px',
    paddingInline: '8px',
  },
}));

const StyledContentGrid = styled(Grid)(({ theme }) => ({
  marginLeft: '24px',
  marginRight: '24px',

  [theme.breakpoints.down('md')]: {
    marginLeft: '8px',
  },
}));

const TitleTypography = styled(Typography)(() => ({
  display: 'inline-flex',
}));

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const IconTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2.0),
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.primary[600],
  },
}));

const StyledAccordion = styled(Accordion)(() => ({
  marginBottom: '16px',

  '&.MuiPaper-root': {
    borderRadius: '8px',

    '&:before': {
      display: 'none',
    },
  },

  '&:last-of-type': {
    marginBottom: 0,
  },
}));

function LinearProgressWithLabel(
  props: LinearProgressProps & {
    numdeliverables: number | undefined;
    completednumdeliverables: number | undefined;
  },
) {
  const value: number =
    props.numdeliverables !== undefined && props.completednumdeliverables !== undefined
      ? (props.completednumdeliverables / props.numdeliverables) * 100
      : 0;

  return (
    <FlexBox>
      <Box sx={{ width: '100%', mr: 1 }}>
        <StyledLinearProgress variant="determinate" value={value} />
      </Box>
      <Box>
        <Typography variant="body2">{`${props.completednumdeliverables}/${props.numdeliverables}`}</Typography>
      </Box>
    </FlexBox>
  );
}

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  'svg[data-testid="ExpandMoreIcon"]': {
    display: 'inline-block',
    position: 'relative',
  },
  '.Mui-expanded svg[data-testid="ExpandMoreIcon"]': {
    display: 'none',
  },
  'svg[data-testid="ExpandLessIcon"]': {
    display: 'none',
    position: 'relative',
  },
  '.Mui-expanded svg[data-testid="ExpandLessIcon"]': {
    display: 'inline-block',
  },
}));

export const CreateContractButton = styled(IconButton)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  color: theme.palette.grey[600],
  backgroundColor: theme.palette.grey[50],
  borderRadius: '4px',
  height: '28px',
  width: '28px',
  marginRight: '8px',
}));

export const ArrowBackIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[600],

  [theme.breakpoints.down('md')]: {
    padding: '5px',
    marginRight: '-7px',
  },
}));

export const ActiveContracts: React.FC<IActiveContractsProps> = (props: IActiveContractsProps) => {
  const [contracts, setContracts] = React.useState<IContractDisplay[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [viewContractDrawer, setViewContractDrawer] = React.useState<boolean>(false);
  const [selectedViewContract, setSelectedViewContract] = React.useState<IContractDisplay>();
  const [resolveDisputeDrawer, setResolveDisputeDrawer] = React.useState<boolean>(false);

  React.useEffect(() => {
    setContracts(props.contracts);
  }, [props.contracts]);

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.ContractChange],
    (res: IContractDisplay) => {
      if (res.chatThreadId === props.selectedThread?.chatThreadId) {
        setContracts(current => {
          updateContract(res);
          const contractIndex = current.findIndex(p => p.contractId === res.contractId);
          // If it's a new contract add it to the top of the list
          if (contractIndex === -1) {
            return [res, ...current];
          } else {
            // If it's a contract in the list then replace it
            return current.map(item => (item.contractId === res.contractId ? res : item));
          }
        });
      }
    },
    [props.selectedThread],
  );

  const hasRevisions = (deliverable: IDeliverable) =>
    deliverable.status !== ContractDeliverableStatus.Approved && deliverable.revisionsRequested > 0;

  return (
    <>
      <StyledGrid container>
        {!props.isCreatingContract && (
          <Grid item xs={12}>
            <Stack direction="row" height="100%" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center" gap={1} ml={isMobile ? '-8px' : 0.5}>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                  {!props.isAdminScreen && (
                    <Box pl={{ xs: '8px', md: 0 }}>
                      <EllipsisWrapperDiv>
                        <EllipsisTypography pt={{ xs: '5px', md: 0 }} mt={isMobile ? '8.5px' : 0} variant="subtitle1">
                          Active / Recent Contracts
                        </EllipsisTypography>
                      </EllipsisWrapperDiv>

                      {isMobile && (
                        <EllipsisWrapperDiv>
                          <EllipsisTypography variant="body2" textTransform="capitalize">
                            {`With ${props.selectedThread?.displayName}`}
                          </EllipsisTypography>
                        </EllipsisWrapperDiv>
                      )}
                    </Box>
                  )}
                  {isMobile && (
                    <ArrowBackIconButton onClick={props.handleMiddleClick}>
                      <Close />
                    </ArrowBackIconButton>
                  )}
                </Stack>
                {props.isAdminScreen && (
                  <Grid container>
                    <Grid item xs={6}>
                      <TitleTypography variant="subtitle1">Disputed Contract</TitleTypography>
                    </Grid>
                    {props.selectedThread !== undefined && (
                      <Grid item xs={6} container justifyContent="right">
                        <Grid>
                          <RoundButton
                            onClick={() => setResolveDisputeDrawer(true)}
                            variant="contained"
                            fullWidth={isMobile != undefined && isMobile}
                          >
                            Resolve
                          </RoundButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Stack>
            </Stack>
          </Grid>
        )}
      </StyledGrid>

      {!props.isCreatingContract && (
        <Grid
          item
          xs={12}
          pr={{ xs: 0.5, md: 0 }}
          pl={{ xs: 1, md: 0 }}
          overflow="auto"
          maxHeight={isMobile ? 'calc(100svh - 165px)' : 'calc(100svh - 162px)'}
        >
          {contracts.map((item: IContractDisplay, i: number) => {
            const currencySymbol = getCurrencySymbol(item.currency);

            return (
              <StyledAccordion elevation={10} key={i} disableGutters expanded={props.isAdminScreen}>
                <StyledAccordionSummary>
                  <Stack direction="column" gap={2} width="100%">
                    <JustifiedBox>
                      <FlexBox ml="-5px">
                        {item.contractType === ContractType.Retainer && (
                          <StyledAvatar>
                            <RepeatIcon sx={{ stroke: theme.palette.primary[300], fill: 'none', strokeWidth: 2.2 }} />
                          </StyledAvatar>
                        )}
                        {item.contractType === ContractType.FixedPrice && (
                          <StyledAvatar>
                            <FixedPriceIcon
                              sx={{ stroke: theme.palette.primary[300], fill: 'none', strokeWidth: 2.2 }}
                            />
                          </StyledAvatar>
                        )}

                        <IconTypography variant="subtitle2" fontWeight={700} ml="12px !important">
                          {item.name}
                        </IconTypography>
                      </FlexBox>

                      <ExpandMoreIcon />
                      <ExpandLessIcon />
                    </JustifiedBox>
                    {item.status !== ContractStatus.Cancelled && item.contractType === ContractType.FixedPrice && (
                      <Box>
                        <LinearProgressWithLabel
                          numdeliverables={item.numDeliverables}
                          completednumdeliverables={item.numCompletedDeliverables}
                        />
                      </Box>
                    )}

                    <JustifiedBox>
                      <FlexBox>
                        {item.viewType == ContractViewType.Sell ? <ArrowBlockLeftIcon /> : <ArrowBlockRightIcon />}

                        <IconTypography variant="subtitle2" color={theme.palette.common.black}>
                          {currencySymbol}
                          {item.contractType == ContractType.FixedPrice && item.displayAmount}
                          {item.contractType == ContractType.Retainer && item.recurringAmount}
                        </IconTypography>
                      </FlexBox>
                      <Typography component="span" variant="body2" color={theme.palette.grey[500]}>
                        {item.paymentStatusText}
                      </Typography>
                    </JustifiedBox>

                    {item.contractType === ContractType.Retainer && (
                      <JustifiedBox component="div">
                        <FlexBox>
                          <MonetizationOnOutlinedIcon />
                          <IconTypography variant="subtitle2">Contract total</IconTypography>
                        </FlexBox>
                        <IconTypography variant="body2">
                          {currencySymbol}
                          {item.displayAmount}
                        </IconTypography>
                      </JustifiedBox>
                    )}

                    <JustifiedBox component="div">
                      <FlexBox>
                        <ReceiptOutlinedIcon />
                        <IconTypography variant="subtitle2">Contract type</IconTypography>
                      </FlexBox>
                      <IconTypography variant="body2">
                        {item.contractType === ContractType.FixedPrice && 'Fixed Price'}
                        {item.contractType === ContractType.Retainer && item.recurrence?.toString() + ' Retainer'}
                      </IconTypography>
                    </JustifiedBox>
                    {props.selectedThread?.type === ChatType.Group && (
                      <JustifiedBox>
                        <FlexBox>
                          <UserAvatar
                            sx={{ width: 24, height: 24, fontSize: '0.75rem' }}
                            userId={
                              item.buyerAdditionalDetails
                                ? item.buyerAdditionalDetails?.sellerId
                                : item.sellerAdditionalDetails?.buyerId
                            }
                            displayName={
                              item.buyerAdditionalDetails
                                ? item.buyerAdditionalDetails?.sellerDisplayName
                                : item.sellerAdditionalDetails?.buyerDisplayName
                            }
                          />
                          <IconTypography variant="subtitle2" ml="12px !important">
                            {item.buyerAdditionalDetails
                              ? item.buyerAdditionalDetails?.sellerDisplayName
                              : item.sellerAdditionalDetails?.buyerDisplayName}
                          </IconTypography>
                        </FlexBox>
                      </JustifiedBox>
                    )}
                  </Stack>
                </StyledAccordionSummary>

                <AccordionDetails>
                  <Stack direction="column" spacing={0} width="100%" gap={2}>
                    <JustifiedBox>
                      <FlexBox>
                        <FactCheckOutlinedIcon />
                        <IconTypography variant="subtitle2">Status</IconTypography>
                      </FlexBox>
                      {(item.status === ContractStatus.Created || item.status === ContractStatus.Offered) && (
                        <Typography component="span" variant="body2" color={theme.palette.grey[500]}>
                          Not Started
                        </Typography>
                      )}
                      {item.status === ContractStatus.Cancelled && (
                        <Typography component="span" variant="body2" color={theme.palette.grey[500]}>
                          Cancelled
                        </Typography>
                      )}
                      {item.status === ContractStatus.InProgress && (
                        <Chip
                          label={
                            <Typography variant="body2" color={theme.palette.common.white}>
                              In Progress
                            </Typography>
                          }
                          color="warning"
                          size="small"
                        />
                      )}
                      {item.status === ContractStatus.Disputed && (
                        <Chip
                          label={
                            <Typography variant="body2" color={theme.palette.common.white}>
                              Disputed
                            </Typography>
                          }
                          color="error"
                          size="small"
                        />
                      )}
                      {item.status === ContractStatus.Complete && (
                        <Chip
                          label={
                            <Typography variant="body2" color={theme.palette.common.white}>
                              Complete
                            </Typography>
                          }
                          color="success"
                          size="small"
                        />
                      )}
                    </JustifiedBox>

                    {item.contractType == ContractType.FixedPrice && (
                      <Stack gap={1}>
                        <JustifiedBox>
                          <FlexBox>
                            <CheckDoneIcon />
                            <IconTypography variant="subtitle2">Deliverables</IconTypography>
                          </FlexBox>
                          {item.deliverables.some(d => hasRevisions(d)) && (
                            <FlexBox>
                              <Typography variant="subtitle2">Revisions</Typography>
                            </FlexBox>
                          )}
                        </JustifiedBox>

                        {item.deliverables.map((deliverable: IDeliverable, p: number) => (
                          <React.Fragment key={p}>
                            <JustifiedBox component="div">
                              <DeliverablesFlexBox>
                                <DeliverableIconBox>
                                  {deliverable.status === ContractDeliverableStatus.Approved && (
                                    <CheckCircleOutlinedIcon color="success" />
                                  )}
                                  {deliverable.status === ContractDeliverableStatus.WaitingApproval && (
                                    <AccessTime color="warning" titleAccess="Awaiting Approval" />
                                  )}
                                  {deliverable.status !== ContractDeliverableStatus.Approved &&
                                    deliverable.status !== ContractDeliverableStatus.WaitingApproval && (
                                      <CheckBoxOutlineBlankOutlinedIcon color="disabled" />
                                    )}
                                </DeliverableIconBox>
                                <DeliverableTypography variant="body2">{deliverable.name}</DeliverableTypography>
                              </DeliverablesFlexBox>
                              {hasRevisions(deliverable) && (
                                <FlexBox>
                                  <IndentSmallTypography variant="body2">
                                    {deliverable.revisionsRequested}/{item.revisions}
                                  </IndentSmallTypography>
                                </FlexBox>
                              )}
                            </JustifiedBox>
                          </React.Fragment>
                        ))}
                      </Stack>
                    )}

                    {item.contractType === ContractType.FixedPrice && (
                      <>
                        <Stack gap={1}>
                          <FlexBox>
                            <Typography variant="subtitle2">Terms</Typography>
                          </FlexBox>

                          <FlexBox>
                            <AccessTime />
                            <IndentSmallTypography variant="body2">{item.delivery} days delivery</IndentSmallTypography>
                          </FlexBox>

                          <FlexBox>
                            <Tune />
                            <IndentSmallTypography variant="body2">{item.revisions} Revisions</IndentSmallTypography>
                          </FlexBox>
                        </Stack>
                        <JustifiedBox>
                          <FlexBox>
                            <CalendarTodayOutlinedIcon />
                            <IconTypography variant="subtitle2">Deadline</IconTypography>
                          </FlexBox>
                          <IconTypography variant="body2">{dateStringFromDate(item.deadlineDate)}</IconTypography>
                        </JustifiedBox>
                      </>
                    )}

                    {item.contractType === ContractType.Retainer && (
                      <>
                        <JustifiedBox>
                          <FlexBox>
                            <LoopIcon />
                            <IconTypography variant="subtitle2">Recurrence</IconTypography>
                          </FlexBox>
                          <IconTypography variant="body2">{item.recurrence}</IconTypography>
                        </JustifiedBox>
                        <JustifiedBox>
                          <FlexBox>
                            <AccessAlarmIcon />
                            <IconTypography variant="subtitle2">Duration</IconTypography>
                          </FlexBox>
                          <IconTypography variant="body2">{item.durationText}</IconTypography>
                        </JustifiedBox>
                        <JustifiedBox>
                          <FlexBox>
                            <CalendarTodayOutlinedIcon />
                            <IconTypography variant="subtitle2">End date</IconTypography>
                          </FlexBox>
                          <IconTypography variant="body2">{dateStringFromDate(item.endDate)}</IconTypography>
                        </JustifiedBox>
                        <JustifiedBox>
                          <FlexBox>
                            <PaymentOutlinedIcon />
                            <IconTypography variant="subtitle2">Payment release</IconTypography>
                          </FlexBox>
                          <IconTypography variant="body2">{item.releaseText}</IconTypography>
                        </JustifiedBox>
                      </>
                    )}

                    <JustifiedBox>
                      {item.sellerAdditionalDetails && item.viewType === ContractViewType.Sell && (
                        <FlexBox>
                          <ArrowBlockLeftIcon />
                          <IconTypography variant="subtitle2">Sell</IconTypography>
                        </FlexBox>
                      )}
                      {item.buyerAdditionalDetails && item.viewType === ContractViewType.Buy && (
                        <FlexBox>
                          <ArrowBlockRightIcon />
                          <IconTypography variant="subtitle2">Purchase</IconTypography>
                        </FlexBox>
                      )}
                    </JustifiedBox>
                    <Grid container>
                      <RoundButton
                        variant="contained"
                        type="submit"
                        aria-label="view contract button"
                        size="medium"
                        fullWidth
                        startIcon={<ViewContractIcon style={{ color: theme.palette.common.white }} />}
                        onClick={() => {
                          setSelectedViewContract(item);
                          setViewContractDrawer(true);
                        }}
                      >
                        View Contract
                      </RoundButton>
                    </Grid>
                  </Stack>
                </AccordionDetails>
              </StyledAccordion>
            );
          })}
          {selectedViewContract && (
            <SwipeableContractDrawer
              contract={selectedViewContract}
              drawer={viewContractDrawer}
              setDrawer={setViewContractDrawer}
            />
          )}
        </Grid>
      )}

      {contracts.length === 0 && !props.isCreatingContract && (
        <StyledContentGrid>
          <Typography variant="body1" component="div" align="left" sx={{ mb: 1, display: 'inline-flex' }}>
            No active contacts right now
          </Typography>
        </StyledContentGrid>
      )}

      <ResolveDisputeDrawer
        setOpen={setResolveDisputeDrawer}
        open={resolveDisputeDrawer}
        selectedContract={props.contracts[0]}
      />
    </>
  );
};
