import { PartnerConfirmation } from 'components/partner/partnerConfirmation';
import PartnerForm from 'components/partner/partnerForm';
import { useState } from 'react';
import { useTitle } from 'utils/router';

export default function Partner() {
  useTitle('Join our partner program');
  const [submitted, setSubmitted] = useState(false);
  return <>{!submitted ? <PartnerForm setSubmitted={setSubmitted} /> : <PartnerConfirmation />}</>;
}
