import { DrawerBody, DrawerHeader } from '../StyledDrawer/DrawerStyles';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { RoundButton } from '../Button/RoundButton';
import { useFormik } from 'formik';
import { number, object } from 'yup';
import { RefundType } from 'global/enums/RefundType';
import { getCurrencySymbol } from '../Select/CountrySelect';
import { ContractPayoutStatus, ContractType, IContract, IRefundContract } from 'global/interfaces/contract';
import { refundContract } from 'services/contractService';
import { showError } from 'utils/errorHandler';
import useProgressBar from 'global/hooks/useProgressBar';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface IRefundContractProps {
  contract: IContract;
  setDrawer?: React.Dispatch<boolean>;
  setRefundOption?: React.Dispatch<boolean>;
}

const StyledTypography = styled(Typography)(() => ({
  overflowWrap: 'break-word',
}));

export function RefundContract({ contract, setRefundOption, setDrawer }: IRefundContractProps) {
  const currencySymbol = getCurrencySymbol(contract.currency);
  const [progress, showProgress] = useProgressBar();
  const theme = useTheme();

  let availableBalance = 0;

  if (contract.contractType === ContractType.FixedPrice) {
    availableBalance = contract.displayAmount;
    if (contract.settlementRefund) availableBalance = availableBalance - contract.settlementRefund.paymentRefundTotal;
    if (
      contract.sellerAdditionalDetails?.payoutStatus === ContractPayoutStatus.Partial &&
      contract.sellerAdditionalDetails.payoutAmountContractCurrency
    )
      availableBalance = availableBalance - (1 - (contract.endRelease ?? 1)) * contract.displayAmount;
  } else if (contract.contractType === ContractType.Retainer && contract.settlementRefund) {
    availableBalance = (contract.recurringAmount ?? 0) - contract.settlementRefund.paymentRefundTotal;
  } else if (contract.contractType === ContractType.Retainer) {
    availableBalance = contract.recurringAmount ?? 0;
  } else {
    availableBalance = contract.displayAmount;
  }

  const form = useFormik<IRefundContract>({
    initialValues: {
      partialRefundType: -1,
      refundAmount: undefined,
    },
    onSubmit: (values): void => {
      showProgress(true);
      refundContract(contract.contractId, values)
        .then(() => {
          showProgress(false);
          if (setDrawer !== undefined) {
            if (setRefundOption) {
              setRefundOption(false);
            }
            setDrawer(false);
          }
        })
        .catch(e => {
          showProgress(false);
          showError(e);
        });
    },
    validationSchema: object({
      partialRefundType: number()
        .nullable()
        .required('You must select a refund option')
        .min(RefundType.Partial, 'You must select a refund option'),
      refundAmount: number()
        .notRequired()
        .nullable()
        .when('partialRefundType', {
          is: RefundType.Partial,
          then: number()
            .nullable()
            .required('Refund amount is required')
            .min(0.9, `The minimum refund amount is ${currencySymbol ?? `${currencySymbol} `}0.01`)
            .max(availableBalance, `The maximum refund amount possible is ${availableBalance.toFixed(2)}`),
        }),
    }),
  });

  return (
    <>
      <DrawerHeader container>
        <Stack justifyContent="center" gap={2} width="100%">
          <Stack direction="row" width="100%" gap={1} justifyContent="space-between" alignItems="center">
            <StyledTypography variant="h6">Refund Contract </StyledTypography>

            <IconButton onClick={() => setDrawer && setDrawer(false)} sx={{ mr: '-12px' }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Stack>
        </Stack>
      </DrawerHeader>
      <DrawerBody item container>
        {contract.contractType == ContractType.FixedPrice && (
          <Typography variant="body1">
            Refund for fixed price contract {contract.name}. Contract value is {currencySymbol}
            {contract.displayAmount}
          </Typography>
        )}
        {contract.contractType == ContractType.Retainer && (
          <Typography variant="body1">
            Refund for retainer contract {contract.name}. {contract.recurrence} value is {currencySymbol}
            {contract.recurringAmount}
          </Typography>
        )}
        <Grid item container xs={12} mt={2}>
          <form onSubmit={form.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    name="partialRefundType"
                    value={form.values.partialRefundType}
                    onChange={e => {
                      form.handleChange(e);
                    }}
                    error={form.touched.partialRefundType && form.errors.partialRefundType !== undefined}
                    fullWidth
                    size="small"
                  >
                    <MenuItem value={-1} disabled>
                      Please select a refund type
                    </MenuItem>
                    <MenuItem value={RefundType.Partial}>Partial Refund</MenuItem>
                    <MenuItem
                      value={RefundType.Full}
                      disabled={contract.sellerAdditionalDetails?.payoutStatus == ContractPayoutStatus.Partial}
                    >
                      Full Refund
                    </MenuItem>
                  </Select>
                  <FormHelperText error>
                    {form.touched.partialRefundType !== undefined &&
                    form.errors.partialRefundType !== undefined &&
                    Boolean(form.errors.partialRefundType)
                      ? form.errors.partialRefundType
                      : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {form.values.partialRefundType === RefundType.Partial && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      name="refundAmount"
                      value={form.values.refundAmount ?? ''}
                      onChange={form.handleChange}
                      error={form.touched.refundAmount && form.errors.refundAmount !== undefined}
                      helperText={form.touched.refundAmount && form.errors.refundAmount}
                      fullWidth
                      label="Enter a refund amount"
                      size="small"
                      type="number"
                      InputProps={{
                        startAdornment: <Typography>{currencySymbol}</Typography>,
                      }}
                    />
                    <FormHelperText
                      style={{
                        color:
                          form.touched.refundAmount && form.errors.refundAmount !== undefined
                            ? theme.palette.error.dark
                            : theme.palette.primary.dark,
                        fontSize: '10px',
                        textAlign: 'right',
                      }}
                    ></FormHelperText>
                  </FormControl>
                </Grid>
              )}
              {form.values.partialRefundType === RefundType.Full && (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    *** Please note Stripe Fees are not refundable. A full refund will result in a negative balance for
                    this {contract.contractType == ContractType.Retainer ? ' period ' : ' contract '}which will be
                    deducted from future earnings.
                  </Typography>
                </Grid>
              )}
              <Grid container item mt={2} columnSpacing={2}>
                <Grid item xs={6}>
                  <RoundButton fullWidth variant="outlined" onClick={() => setRefundOption && setRefundOption(false)}>
                    Cancel
                  </RoundButton>
                </Grid>
                <Grid item xs={6}>
                  <RoundButton fullWidth loading={false} variant="contained" type="submit">
                    Confirm
                  </RoundButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        {progress}
      </DrawerBody>
    </>
  );
}
