import {
  Box,
  Container,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { useFormik } from 'formik';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { nameof } from 'ts-simple-nameof';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from 'contexts/AuthContext';

import { useLocalization } from 'global/hooks/useLocalization';

import { showError } from 'utils/errorHandler';
import { useNavigate } from 'react-router-dom';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { IPartnerForm } from 'global/interfaces/partner';
import { partnerValidationSchema } from 'global/validations/partner';
import { sendPartnerDetails } from 'services/partnerService';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: '48px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '32px',
  },
}));

const StyledFormGrid = styled(Grid)(() => ({
  marginTop: '24px',
}));

const StyledButtonGrid = styled(Grid)(() => ({
  marginTop: '20px',
  marginBottom: '40px',
}));

const StyledHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: '14px',
  color: theme.palette.error.main,
}));

interface StyledBoxProps {
  backgroundColor?: string;
}

const StyledBannerBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'backgroundColor',
})<StyledBoxProps>(({ backgroundColor, theme }) => ({
  background: backgroundColor ? backgroundColor : theme.palette.blue[200],
}));

const BannerStack = styled(Stack)(({ theme }) => ({
  paddingTop: '48px',
  paddingBottom: '48px',

  [theme.breakpoints.up('md')]: {
    paddingTop: '80px',
    paddingBottom: '80px',
  },
}));

const HeroHeadlineTypohgraphy = styled(Typography)(({ theme }) => ({
  color: '#101828',
  fontWeight: 700,
  fontFamily: 'Inter',
  letterSpacing: '-3%',
  lineHeight: '128%',

  fontSize: '48px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '32px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
  },
}));

export default function PartnerForm({ setSubmitted }: { setSubmitted: Dispatch<boolean> }) {
  const authContext = useContext(AuthContext);
  const isLoggedIn = authContext.user !== null;
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);
  const navigate = useNavigate();

  const handleCancelClick = (): void => {
    navigate(-1);
  };

  const form = useFormik<IPartnerForm>({
    initialValues: {
      name: '',
      email: '',
      website: '',
      linkedin: '',
      token: '',
      currency: '',
      userId: undefined,
    },
    onSubmit: values => {
      if (isLoggedIn || (form.values.token !== '' && form.values.token !== null)) {
        setLoading(true);
        sendPartnerDetails(values)
          .then(() => setSubmitted(true))
          .catch(showError);
      }
    },
    validationSchema: partnerValidationSchema(!isLoggedIn),
  });

  useEffect(() => {
    if (isLoggedIn) {
      form.setFieldValue(
        nameof<IPartnerForm>(x => x.name),
        authContext.user?.firstName,
      );
      form.setFieldValue(
        nameof<IPartnerForm>(x => x.email),
        authContext.user?.email,
      );
      form.setFieldValue(
        nameof<IPartnerForm>(x => x.userId),
        authContext.user?.id,
      );
    }
  }, [authContext.user]);

  useEffect(() => {
    form.setFieldValue(
      nameof<IPartnerForm>(x => x.currency),
      localCurrencySymbol,
    );
  }, [localCurrencySymbol]);

  return (
    <>
      <StyledBannerBox backgroundColor={'#D2ECF0'}>
        <Container maxWidth="md">
          <BannerStack direction={'column'} rowGap={2}>
            <HeroHeadlineTypohgraphy variant="subtitle1" textAlign={'center'}>
              Join the Shoutt partner program and earn 25% for life!
            </HeroHeadlineTypohgraphy>
          </BannerStack>
        </Container>
      </StyledBannerBox>
      <StyledContainer maxWidth={'md'}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid container item xs={12}>
              <Grid container item xs={12}>
                <Stack spacing={3} mt={1}>
                  <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                    At Shoutt, we’re on a mission to flip the script on freelance platforms. We’re all about
                    high-quality connections, empowering freelancers, and connecting founders with freelancers—without
                    the hassle. And now, we’re inviting you to be a part of this revolution!
                  </Typography>
                  <Typography variant={isMobileScreen ? 'h6' : 'h5'}>Why partner with Shoutt?</Typography>
                  <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                    <b>Earn a lifetime passive income:</b> When you join our Partner Program, you’ll earn 25% of the
                    subscription fees for every referral—for as long they stay with us! It’s a simple way to build a
                    steady, passive income while supporting a community of freelancers and businesses.
                  </Typography>
                  <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                    <b>No commissions, just connections:</b> We’ve turned the traditional model upside down. Shoutt
                    doesn’t take commissions from freelancers or clients. Instead, we charge a simple subscription fee
                    of {localCurrencySymbol}
                    {price} per month, and only one party needs a paid plan to get started. The other side can always
                    use a free account, making it easier for everyone to connect, create contracts, and get work done.
                  </Typography>
                  <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                    <b>Build a thriving community: </b> As a Shoutt Partner, you’re not just earning money—you’re
                    helping us build a community where freelancers and startups thrive together. Your support helps us
                    maintain a space that values quality over quantity, and connections over transactions.
                  </Typography>
                  <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                    <b>Reliable, cookie-free tracking: </b>Our tracking system is as reliable as it gets. We don’t rely
                    on cookies, so you can rest easy knowing that every referral is tracked accurately and your earnings
                    are secure.
                  </Typography>
                  <Typography variant={isMobileScreen ? 'h6' : 'h5'}>How it works</Typography>
                  <ol>
                    <li>
                      <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                        <b>Refer</b>: Share Shoutt with your network using your unique referral link. Our creative team
                        is here to assist with any graphics or videos that you might need.
                      </Typography>
                    </li>
                    <li style={{ marginTop: '8px' }}>
                      <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                        <b>Earn:</b> Every time someone subscribes using your link, you earn 25% of their subscription
                        fee—forever.
                      </Typography>
                    </li>
                    <li style={{ marginTop: '8px' }}>
                      <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
                        <b>Grow: </b>As your referrals succeed and grow, so does your passive income.
                      </Typography>
                    </li>
                  </ol>
                </Stack>
              </Grid>

              <StyledFormGrid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={'h6'} mb={2}>
                    Apply Now
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name={nameof<IPartnerForm>(x => x.name)}
                        label="Your Name *"
                        value={form.values.name}
                        variant="outlined"
                        fullWidth
                        onChange={form.handleChange}
                        error={form.touched.name !== undefined && Boolean(form.errors.name)}
                        helperText={form.touched.name !== undefined ? form.errors.name : ''}
                        disabled={isLoggedIn}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name={nameof<IPartnerForm>(x => x.email)}
                        label="Email *"
                        variant="outlined"
                        fullWidth
                        value={form.values.email}
                        onChange={form.handleChange}
                        error={form.touched.email !== undefined && Boolean(form.errors.email)}
                        helperText={form.touched.email !== undefined ? form.errors.email : ''}
                        disabled={isLoggedIn}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name={nameof<IPartnerForm>(x => x.website)}
                        label="Website *"
                        variant="outlined"
                        fullWidth
                        value={form.values.website}
                        placeholder="i.e your website"
                        onChange={form.handleChange}
                        error={form.touched.website !== undefined && Boolean(form.errors.website)}
                        helperText={form.touched.website !== undefined ? form.errors.website : ''}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name={nameof<IPartnerForm>(x => x.linkedin)}
                        label="LinkedIn *"
                        variant="outlined"
                        fullWidth
                        value={form.values.linkedin}
                        placeholder="i.e your Linked In url"
                        onChange={form.handleChange}
                        error={form.touched.linkedin !== undefined && Boolean(form.errors.linkedin)}
                        helperText={form.touched.linkedin !== undefined ? form.errors.linkedin : ''}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                {!isLoggedIn && (
                  <Grid item>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}
                      onChange={(value: string | null) => {
                        form.setFieldValue(
                          nameof<IPartnerForm>(x => x.token),
                          value,
                        );
                      }}
                      onErrored={() => console.error('reCAPTCHA initialization error')}
                    />
                    {form.errors.token && form.submitCount > 0 && (
                      <StyledHelperText>{form.errors.token}</StyledHelperText>
                    )}
                  </Grid>
                )}
                <StyledButtonGrid container item xs={12} justifyContent={'flex-end'}>
                  <Grid container item xs={12} sm={6} spacing={1}>
                    <Grid item xs={6}>
                      <RoundButton variant="outlined" fullWidth onClick={handleCancelClick}>
                        Cancel
                      </RoundButton>
                    </Grid>
                    <Grid item xs={6}>
                      <RoundButton loading={loading} variant="contained" type="submit" fullWidth>
                        Submit
                      </RoundButton>
                    </Grid>
                  </Grid>
                </StyledButtonGrid>
              </StyledFormGrid>
            </Grid>
          </Grid>
        </form>
      </StyledContainer>
    </>
  );
}
