import SignImg from 'assets/images/dashboard/biteSize/sign.webp';
import EscrowImg from 'assets/images/dashboard/biteSize/escrow.webp';
import MarketImg from 'assets/images/dashboard/biteSize/marketplace.webp';
import InviteImg from 'assets/images/dashboard/biteSize/invite.webp';

import { TypographyProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocalization } from 'global/hooks/useLocalization';

interface Paragraph {
  text: React.ReactElement | string;
  variant: TypographyProps['variant'];
}

interface Content {
  id: number;
  imgSrc: string | undefined;
  paragraphs: Paragraph[];
}

function RewardPrice() {
  const { localCurrencySymbol } = useLocalization(19.99);
  return (
    <>
      Simply hit the send invite button and get a {localCurrencySymbol}20 reward when you complete your first contract
      with them.
    </>
  );
}

export const contentList: Content[] = [
  {
    id: 0,
    imgSrc: InviteImg,
    paragraphs: [
      {
        text: 'Get paid for referrals',
        variant: 'subtitle1',
      },
      {
        text: 'We’re all about high-quality connections, empowering freelancers, and connecting founders with freelancers—without the hassle. And now, we’re inviting you to be a part of this revolution!',
        variant: 'body2',
      },
      {
        text: (
          <>
            Join the Shoutt <Link to="/partner">partner program</Link> today and you’ll earn 25% of the subscription
            fees for every referral!
          </>
        ),
        variant: 'body2',
      },
    ],
  },
  {
    id: 1,
    imgSrc: InviteImg,
    paragraphs: [
      {
        text: 'Invite someone to Shoutt',
        variant: 'subtitle1',
      },
      {
        text: 'You can invite anyone to come and work with you on Shoutt to get reviews and boost your Shoutt profile.',
        variant: 'body2',
      },
      {
        text: <RewardPrice />,
        variant: 'body2',
      },
    ],
  },

  {
    id: 2,
    imgSrc: MarketImg,
    paragraphs: [
      {
        text: 'How the marketplace works ',
        variant: 'subtitle1',
      },
      {
        text: (
          <>
            If you’re looking to sell services, you can apply for inclusion on our marketplace. You will need to
            complete your profile and go through our{' '}
            <Link to="https://help.shoutt.co/en/articles/349378" target="_blank">
              vetting process
            </Link>
            .
          </>
        ),
        variant: 'body2',
      },
      {
        text: 'If you’re looking to find talent, simply post a job or search and connect with freelancers to get the conversation going.',
        variant: 'body2',
      },
    ],
  },
  {
    id: 3,
    imgSrc: SignImg,
    paragraphs: [
      {
        text: 'How contracts work',
        variant: 'subtitle1',
      },
      {
        text: 'Either the buyer or seller can initiate the contract process. You can choose one of your connections or you can send an invitation to someone to join Shoutt.',
        variant: 'body2',
      },
      {
        text: 'All contract updates will appear in the workspace where both parties can collaborate together. All contracts are 100% commission free. ',
        variant: 'body2',
      },
    ],
  },
  {
    id: 4,
    imgSrc: SignImg,
    paragraphs: [
      {
        text: 'Retainers',
        variant: 'subtitle1',
      },
      {
        text: 'Freelancers can be hired on a rolling basis or for a set period of time. Automatic payments can be released on a weekly or monthly basis.',
        variant: 'body2',
      },
      {
        text: 'You can also agree on a payout schedule between yourselves, such as the middle or end of each period.',
        variant: 'body2',
      },
    ],
  },
  {
    id: 5,
    imgSrc: SignImg,
    paragraphs: [
      {
        text: 'Fixed-price contracts',
        variant: 'subtitle1',
      },
      {
        text: 'Fixed-price contracts are predefined with a clear list of deliverables that freelancers need to deliver against.',
        variant: 'body2',
      },
      {
        text: 'The contract includes options for specifying the number of revision rounds that are included and how many days the seller has to deliver the deliverables.',
        variant: 'body2',
      },
    ],
  },
  {
    id: 6,
    imgSrc: EscrowImg,
    paragraphs: [
      {
        text: 'How payment escrow works',
        variant: 'subtitle1',
      },
      {
        text: 'The buyer always pays upfront and the funds are held by Shoutt in escrow. For fixed price projects, the funds are released once the work has been approved by the buyer. For retainers, the funds are released based on the agreed terms. This helps to protect both sides.',
        variant: 'body2',
      },
      {
        text: 'Shoutt allows users to transact safely in 40+ countries with support for USD, GBP or EUR.',
        variant: 'body2',
      },
    ],
  },
];
