import { MainSectionBox } from './MainSectionBox';
import { Box, CircularProgress, Grow, IconButton, Stack, styled, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ContractViewType, IContractBasic } from 'global/interfaces/contract';
import { useCustomEventListener } from 'react-custom-events';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { getContractsInEscrow } from 'services/contractService';
import { showError } from 'utils/errorHandler';
import { getCurrencySymbol } from 'components/common/Select/CountrySelect';

import { ArrowBlockLeftIcon } from 'components/icon/ArrowBlockLeftIcon';
import { ArrowBlockRightIcon } from 'components/icon/ArrowBlockRightIcon';
import { MessageIcon } from 'components/icon/MessageIcon';

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export default function ItemEscrow() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<IContractBasic[]>([]);

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.ContractChange],
    () => {
      loadEscrows();
    },
    [],
  );

  useEffect(() => {
    loadEscrows();
  }, []);

  const loadEscrows = () => {
    setLoading(true);
    setContracts([]);

    getContractsInEscrow()
      .then((res: IContractBasic[]) => {
        setContracts(res);
        setLoading(false);
      })
      .catch(showError);
  };
  return (
    <MainSectionBox>
      <Stack direction="row" spacing={1.5} mb={1}>
        <Typography variant="subtitle1" fontWeight={600}>
          In Escrow ({contracts.length})
        </Typography>
      </Stack>
      {loading && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}
      {!loading && contracts.length === 0 && (
        <Box display={'flex'}>
          <Stack direction="column" spacing={2} mb={2} mt={1}>
            <Typography variant="body2">No contracts currently in escrow!</Typography>
          </Stack>
        </Box>
      )}
      {!loading && contracts.length > 0 && (
        <Grow in={true} timeout={500}>
          <Box mt={2}>
            {contracts.map((contract, index: number) => {
              const currencySybmol = getCurrencySymbol(contract.currency);

              return (
                <Stack key={index} direction="column" spacing={0.75} mt={0.5} mb={1}>
                  <JustifiedBox>
                    <FlexBox title={contract.viewType == ContractViewType.Sell ? 'Sell' : 'Purchase'}>
                      {contract.viewType == ContractViewType.Sell ? (
                        <ArrowBlockLeftIcon sx={{ width: 24, height: 24 }} />
                      ) : (
                        <ArrowBlockRightIcon sx={{ width: 24, height: 24 }} />
                      )}

                      <Typography variant="subtitle2" ml={1}>
                        {contract.otherUserDisplayName}
                      </Typography>
                    </FlexBox>

                    <FlexBox>
                      <Typography variant="body2">
                        {currencySybmol}
                        {contract.amount}
                      </Typography>
                      <IconButton onClick={() => navigate(`/workroom/${contract.chatThreadId}`)} sx={{ ml: 1 }}>
                        <MessageIcon />
                      </IconButton>
                    </FlexBox>
                  </JustifiedBox>
                </Stack>
              );
            })}
          </Box>
        </Grow>
      )}
    </MainSectionBox>
  );
}
