import { MainSectionBox } from './MainSectionBox';
import { Box, CircularProgress, Grow, IconButton, Stack, styled, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ContractActionType, IContractAction } from 'global/interfaces/contract';
import { getActionRequiredContracts } from 'services/contractService';
import { showError } from 'utils/errorHandler';

import { MakePaymentIcon } from 'components/icon/MakePaymentIcon';
import { useNavigate } from 'react-router-dom';
import { getCurrencySymbol } from 'components/common/Select/CountrySelect';
import { ReviewContentIcon } from 'components/icon/ReviewContentIcon';
import { RateReviewOutlined } from '@mui/icons-material';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { useCustomEventListener } from 'react-custom-events';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { MessageIcon } from 'components/icon/MessageIcon';
import { FileIcon } from 'components/icon/FileIcon';
import { useIsComponentMounted } from 'global/hooks/useIsComponentMounted';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export default function ItemActionRequired() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<IContractAction[]>([]);
  const isComponentMounted = useIsComponentMounted();

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.ContractChange],
    () => {
      loadActions();
    },
    [],
  );

  useEffect(() => {
    loadActions();
  }, []);

  const loadActions = () => {
    if (isComponentMounted.current) {
      setLoading(true);
      setContracts([]);
    }
    getActionRequiredContracts()
      .then((res: IContractAction[]) => {
        if (isComponentMounted.current) {
          setContracts(res);
          setLoading(false);
        }
      })
      .catch(showError);
  };

  return (
    <MainSectionBox>
      <Stack direction="row" spacing={1.5} mb={1}>
        <Typography variant="subtitle1" fontWeight={600}>
          Action Required ({contracts.length})
        </Typography>
      </Stack>

      {loading && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}
      {!loading && contracts.length === 0 && (
        <Box display={'flex'}>
          <Stack direction="row" spacing={1} mb={2} mt={1}>
            <TaskAltOutlinedIcon sx={{ color: theme.palette.primary.main }} />
            <Typography variant="body2">You have completed all actions!</Typography>
          </Stack>
        </Box>
      )}
      {!loading && contracts.length > 0 && (
        <Grow in={true} timeout={500}>
          <Box mt={2}>
            {contracts.map((contract, index: number) => {
              const currencySymbol = getCurrencySymbol(contract.currency);

              return (
                <Stack key={index} direction="column" spacing={0.75} mt={0.5} mb={1}>
                  <JustifiedBox>
                    <FlexBox>
                      {contract.action == ContractActionType.PaymentRequested && (
                        <MakePaymentIcon sx={{ width: 24, height: 24 }} style={{ color: theme.palette.grey[500] }} />
                      )}
                      {(contract.action == ContractActionType.ReviewRequested ||
                        contract.action == ContractActionType.RevisionRequested) && (
                        <ReviewContentIcon
                          sx={{ width: 20, height: 20, mr: '4px', mb: '1px' }}
                          style={{ color: theme.palette.grey[500] }}
                        />
                      )}
                      {contract.action == ContractActionType.LeaveFeedback && (
                        <RateReviewOutlined
                          style={{
                            color: theme.palette.grey[500],
                            strokeWidth: 0.1,
                            width: 22,
                            height: 22,
                            marginRight: '2px',
                          }}
                        />
                      )}
                      {contract.action == ContractActionType.PaymentFailed && (
                        <ErrorOutlineOutlinedIcon
                          style={{
                            color: theme.palette.grey[500],
                            strokeWidth: 0.5,
                            width: 24,
                            height: 24,
                          }}
                        />
                      )}
                      {contract.action == ContractActionType.AcceptContract && (
                        <FileIcon
                          sx={{ width: 22, height: 22, mr: '2px', mt: '2px' }}
                          style={{ color: theme.palette.grey[500], strokeWidth: 2.2 }}
                        />
                      )}

                      <Stack direction={'column'} spacing={0} sx={{ marginLeft: '8px', marginTop: '8px' }}>
                        <Typography variant="subtitle2" fontWeight={600}>
                          {contract.action == ContractActionType.PaymentRequested && (
                            <>
                              {'Make Payment - '}
                              {currencySymbol}
                              {contract.amount}
                            </>
                          )}
                          {contract.action == ContractActionType.PaymentFailed && 'Update Payment Details'}
                          {contract.action == ContractActionType.ReviewRequested && 'Review Requested'}
                          {contract.action == ContractActionType.RevisionRequested && 'Revision Requested'}
                          {contract.action == ContractActionType.LeaveFeedback && 'Leave Feedback'}
                          {contract.action == ContractActionType.AcceptContract && 'Accept Contract'}
                        </Typography>
                        <Typography variant="caption" fontWeight={300}>
                          {contract.otherUserDisplayName}
                        </Typography>
                      </Stack>
                    </FlexBox>

                    <FlexBox>
                      <IconButton onClick={() => navigate(`/workroom/${contract.chatThreadId}`)}>
                        <MessageIcon />
                      </IconButton>
                    </FlexBox>
                  </JustifiedBox>
                </Stack>
              );
            })}
          </Box>
        </Grow>
      )}
    </MainSectionBox>
  );
}
