import { Add } from '@mui/icons-material';
import { FormControl, FormGroup, Grid, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FastField, FieldProps, FormikProps } from 'formik';
import { IEditableUser } from 'global/interfaces/user';
import { nameof } from 'ts-simple-nameof';
import { StyledIconButton } from './PricingEditor';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { LogoAdornmentHandler } from '../../../common/LogoAdornmentHandler';
import { TextButton } from 'components/common/Button/TextButton';

interface IScreeningLinksProps {
  form: FormikProps<IEditableUser>;
}

const screeningLinksText = 'LinkedIn, YT, Insta, Github, Dribbble, etc';

export const ScreeningLinks = ({ form }: IScreeningLinksProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const addThirdLink = () => {
    const linksToFill = Array(3 - form.values.links.length).fill('');
    form.setFieldValue('links', [...form.values.links, ...linksToFill]);
  };

  const removeThirdLink = () => {
    form.setFieldValue('links', [...form.values.links.slice(0, -1)]);
  };

  return (
    <Grid container>
      <Grid item xs={12} mb={3}>
        <Typography variant={'h6'}>My Links</Typography>
        <Typography variant="body2" mt="4px">
          {form.values.needsVettingValidation ? 'Required: ' : 'Recommended: '}Enter a link to your LinkedIn and any
          additional links for users to learn more about you.
        </Typography>
      </Grid>

      <Grid container columnSpacing={isMobileScreen ? 1 : 2.5} rowSpacing={3}>
        <Grid item xs={12} sm={6}>
          <FormGroup>
            <FormControl>
              <FastField name={`${nameof<IEditableUser>(x => x.links)}.[0]`}>
                {({ field, form: { handleChange } }: FieldProps) => (
                  <TextField
                    name={field.name}
                    value={field.value ?? ''}
                    onChange={handleChange}
                    variant="outlined"
                    label="Link 1"
                    placeholder={screeningLinksText}
                    error={form.touched.links && Boolean(form.errors.links)}
                    helperText={form.touched.links && form.errors.links ? form.errors.links : ''}
                    InputProps={{
                      autoComplete: 'off',
                      startAdornment: (
                        <Stack direction="row" gap={1} marginRight={1}>
                          <LogoAdornmentHandler url={form.values.links[0]} />
                        </Stack>
                      ),
                    }}
                  />
                )}
              </FastField>
            </FormControl>
          </FormGroup>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormGroup>
            <FormControl>
              <FastField name={`${nameof<IEditableUser>(x => x.links)}.[1]`}>
                {({ field, form: { handleChange }, meta }: FieldProps) => (
                  <TextField
                    name={field.name}
                    value={field.value ?? ''}
                    onChange={handleChange}
                    variant="outlined"
                    label="Link 2"
                    placeholder={screeningLinksText}
                    InputProps={{
                      autoComplete: 'off',
                      startAdornment: (
                        <Stack direction="row" gap={1} marginRight={1}>
                          <LogoAdornmentHandler url={form.values.links[1]} />
                        </Stack>
                      ),
                    }}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && Array.isArray(form.errors.links) ? meta.error : ''}
                  />
                )}
              </FastField>
            </FormControl>
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          {form.values.links.length < 3 ? (
            <TextButton size="large" variant="text" startIcon={<Add />} onClick={addThirdLink} fullWidth={false}>
              Add Link
            </TextButton>
          ) : (
            <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
              <Grid item sm={5.85}>
                <FormGroup>
                  <FormControl>
                    <FastField name={`${nameof<IEditableUser>(x => x.links)}.[2]`}>
                      {({ field, form: { handleChange }, meta }: FieldProps) => (
                        <TextField
                          name={field.name}
                          value={field.value ?? ''}
                          onChange={handleChange}
                          variant="outlined"
                          label="Link 3"
                          placeholder={screeningLinksText}
                          InputProps={{
                            autoComplete: 'off',
                            startAdornment: (
                              <Stack direction="row" gap={1} marginRight={1}>
                                <LogoAdornmentHandler url={form.values.links[2]} />
                              </Stack>
                            ),
                          }}
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && Array.isArray(form.errors.links) ? meta.error : ''}
                        />
                      )}
                    </FastField>
                  </FormControl>
                </FormGroup>
              </Grid>

              <StyledIconButton onClick={removeThirdLink}>
                <DeleteOutlineOutlinedIcon />
              </StyledIconButton>
            </Stack>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
