import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageIcon } from 'components/icon/MessageIcon';
import {
  ContractStatus,
  ContractType,
  ContractViewType,
  IContract,
  IContractFilters,
  StatusColourDictionary,
} from 'global/interfaces/contract';
import { ContractFilters } from './filters/contractFilters';
import { IConnection } from 'global/interfaces/connection';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getContractStatusText } from 'utils/contractTextHelper';
import IconButton from '@mui/material/IconButton';
import { dateStringFromDate } from 'utils/dateFormat';
import { StyledMobileAvatar } from 'components/common/StyledTable/StyledTableAvatar';
import { SwipeableContractDrawer } from 'components/common/Contract/SwipeableContractDrawer';
import UserBasicInfo from 'components/network/UserBasicInfo';
import { RoundButton } from 'components/common/Button/RoundButton';
import { getCurrencySymbol } from 'components/common/Select/CountrySelect';
import { ArrowBlockLeftIcon } from 'components/icon/ArrowBlockLeftIcon';
import { ArrowBlockRightIcon } from 'components/icon/ArrowBlockRightIcon';

interface StyledStatusIdicatorProps {
  isCreated: boolean;
}

const StyledSkeleton = styled(Skeleton)(() => ({
  height: 60,
  marginLeft: 25,
}));

const StyledSearchFilters = styled(Grid)(({ theme }) => ({
  marginBottom: '36px',

  [theme.breakpoints.down('md')]: {
    marginBottom: 0,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

const StyledStatusGrid = styled(Grid)(() => ({
  marginBottom: '8px',
}));

const StyledStatusIdicator = styled(Chip, {
  shouldForwardProp: prop => prop !== 'isCreated',
})<StyledStatusIdicatorProps>(({ theme, isCreated }) => ({
  height: '19px',
  padding: '0px, 7px, 0px, 7px',
  borderRadius: '64px',
  color: isCreated ? theme.palette.black[500] : theme.palette.common.white,
}));

const StyledContentTitleGrid = styled(Grid)(() => ({
  marginBottom: '8px',
  alignItems: 'center',
}));

export const StyledIconButton = styled(IconButton)(() => ({
  border: '1px solid',
  borderRadius: '4px',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-head': {
    backgroundColor: theme.palette.grey['A200'],
    fontWeight: 600,
  },
}));

interface IContractHistoryProps {
  contracts: IContract[] | undefined;
  onFilterChange: (filters: IContractFilters) => void;
  onNextPage: () => void;
  loading: boolean;
  connections: IConnection[];
  mobileDrawer: boolean;
  setMobileDrawer: React.Dispatch<boolean>;
}

export const ContractHistory: React.FC<IContractHistoryProps> = props => {
  const [drawer, setDrawer] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<IContract | undefined>(undefined);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <StyledSearchFilters item xs={12}>
        <ContractFilters {...props} onFilterChange={props.onFilterChange} connections={props.connections} />
      </StyledSearchFilters>

      {isMobileScreen ? (
        <>
          <MobileContractHistoryView
            {...props}
            setSelectedContract={setSelectedContract}
            drawer={drawer}
            setDrawer={setDrawer}
          />
        </>
      ) : (
        <DesktopView {...props} setSelectedContract={setSelectedContract} drawer={drawer} setDrawer={setDrawer} />
      )}
      {selectedContract && (
        <SwipeableContractDrawer contract={selectedContract} drawer={drawer} setDrawer={setDrawer} />
      )}
    </>
  );
};

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const DesktopView: React.FC<
  IContractHistoryProps & {
    drawer: boolean;
    setDrawer: React.Dispatch<boolean>;
    setSelectedContract: React.Dispatch<IContract>;
  }
> = ({ contracts, loading, setDrawer, setSelectedContract }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const HeaderData = [
    { id: 'otherParty', label: 'Connection' },
    { id: 'reference', label: 'Title' },
    { id: 'type', label: 'Type' },
    { id: 'status', label: 'Status' },
    { id: 'createdDate', label: 'Created date' },

    { id: 'amountSpent', label: 'Amount' },
    { id: 'options', label: 'Options' },
  ];

  const navigateToThread = (chatThreadId: string) => {
    navigate(`/workroom/${chatThreadId}`);
  };

  const viewSelectedContract = (contract: IContract) => {
    setSelectedContract(contract);
    setDrawer(true);
  };

  return (
    <>
      {!loading ? (
        <>
          {contracts && contracts.length > 0 ? (
            <>
              <TableContainer>
                <Table>
                  <StyledTableHead>
                    <TableRow>
                      {HeaderData.map(headerCell => (
                        <TableCell key={headerCell.id}>
                          <Typography variant="subtitle2">{headerCell.label}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {contracts.map((contract: IContract, i) => {
                      const isBuyer = Boolean(contract.buyerAdditionalDetails);
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            {isBuyer ? (
                              <UserBasicInfo
                                user={{
                                  displayName: contract.buyerAdditionalDetails?.sellerDisplayName,
                                  userId: contract.buyerAdditionalDetails?.sellerId,
                                }}
                              />
                            ) : (
                              <UserBasicInfo
                                user={{
                                  displayName: contract.sellerAdditionalDetails?.buyerDisplayName,
                                  userId: contract.sellerAdditionalDetails?.buyerId,
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">{contract.name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {contract.contractType === ContractType.FixedPrice
                                ? ' Fixed Price'
                                : contract.contractType}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <StyledStatusIdicator
                              isCreated={
                                ContractStatus.Created === contract.status || ContractStatus.Offered === contract.status
                              }
                              label={getContractStatusText(contract)}
                              color={StatusColourDictionary[contract.status]}
                            />
                          </TableCell>

                          <TableCell>
                            <Typography variant="body2">{dateStringFromDate(contract.createdDate)}</Typography>
                          </TableCell>

                          <TableCell>
                            <FlexBox>
                              {contract.viewType == ContractViewType.Sell ? (
                                <ArrowBlockLeftIcon titleAccess="Sell" />
                              ) : (
                                <ArrowBlockRightIcon titleAccess="Purchase" />
                              )}
                              <Typography variant="body2" ml={1} color={theme.palette.grey[600]}>
                                {getCurrencySymbol(contract.currency)}
                                {contract.displayAmount}
                              </Typography>
                            </FlexBox>
                          </TableCell>
                          <TableCell width={100}>
                            <Stack direction="row" gap={1} ml={-1}>
                              <IconButton onClick={() => viewSelectedContract(contract)}>
                                <VisibilityIcon color="primary" />
                              </IconButton>

                              <IconButton onClick={() => navigateToThread(contract.chatThreadId)}>
                                <MessageIcon />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Typography variant="body1" color={theme.palette.grey[500]}>
              No contracts available
            </Typography>
          )}
        </>
      ) : (
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12}>
            {[...Array(4)].map((e, i) => (
              <Grid marginTop={2} key={i}>
                <StyledSkeleton variant="rounded" />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const StyledViewContractButton = styled(RoundButton)(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  color: theme.palette.grey[900],
  '&:hover': {
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[100],
  },
}));

const MobileContractHistoryView: React.FC<
  IContractHistoryProps & {
    setSelectedContract: React.Dispatch<IContract>;
    drawer: boolean;
    setDrawer: React.Dispatch<boolean>;
  }
> = ({ contracts, loading, setDrawer, setSelectedContract }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Grid>
      {!loading ? (
        <Grid container gap="32px">
          {contracts &&
            contracts.length > 0 &&
            contracts?.map((contract: IContract) => {
              const isBuyer = Boolean(contract.buyerAdditionalDetails);
              return (
                <Grid key={contract.contractId} container item justifyContent="space-between">
                  <Grid container alignItems="center" mb={2}>
                    <Grid container item xs={12} alignItems="center">
                      {isBuyer ? (
                        <StyledMobileAvatar
                          displayName={contract.buyerAdditionalDetails!.sellerDisplayName}
                          userId={contract.buyerAdditionalDetails!.sellerId}
                        />
                      ) : (
                        <StyledMobileAvatar
                          displayName={contract.sellerAdditionalDetails?.buyerDisplayName}
                          userId={contract.sellerAdditionalDetails?.buyerId}
                        />
                      )}
                      <StyledTypography variant="subtitle1" textTransform="capitalize">
                        {isBuyer
                          ? contract.buyerAdditionalDetails?.sellerDisplayName
                          : contract.sellerAdditionalDetails?.buyerDisplayName}
                      </StyledTypography>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} alignItems="center">
                    <StyledContentTitleGrid item xs={6}>
                      <Typography variant="subtitle2">Title</Typography>
                    </StyledContentTitleGrid>

                    <StyledContentTitleGrid container item xs={6} justifyContent="flex-end">
                      <Typography variant="body2">{contract.name}</Typography>
                    </StyledContentTitleGrid>
                  </Grid>

                  <Grid container item xs={12} alignItems="center">
                    <StyledContentTitleGrid item xs={6}>
                      <Typography variant="subtitle2">Status</Typography>
                    </StyledContentTitleGrid>

                    <StyledStatusGrid item xs={6} container justifyContent="flex-end">
                      <StyledStatusIdicator
                        isCreated={
                          ContractStatus.Created === contract.status || ContractStatus.Offered === contract.status
                        }
                        label={getContractStatusText(contract)}
                        color={StatusColourDictionary[contract.status]}
                      />
                    </StyledStatusGrid>
                  </Grid>

                  <StyledContentTitleGrid item xs={6}>
                    <Typography variant="subtitle2">Created date</Typography>
                  </StyledContentTitleGrid>

                  <Grid container item xs={6} justifyContent="flex-end">
                    <Typography variant="body2">{dateStringFromDate(contract.createdDate)}</Typography>
                  </Grid>

                  <StyledContentTitleGrid item xs={6}>
                    <Typography variant="subtitle2">Type</Typography>
                  </StyledContentTitleGrid>

                  <Grid container item xs={6} justifyContent="flex-end">
                    <Typography variant="body2">
                      {contract.contractType === ContractType.FixedPrice ? ' Fixed Price' : contract.contractType}
                    </Typography>
                  </Grid>

                  <StyledContentTitleGrid item xs={6}>
                    <Typography variant="subtitle2">Total</Typography>
                  </StyledContentTitleGrid>

                  <Grid container item xs={6} justifyContent="flex-end" gap={1}>
                    {contract.viewType == ContractViewType.Sell ? (
                      <ArrowBlockLeftIcon titleAccess="Sell" />
                    ) : (
                      <ArrowBlockRightIcon titleAccess="Purchase" />
                    )}
                    <Typography variant="body2">
                      {contract.displayAmount
                        ? `${getCurrencySymbol(contract.currency)}${contract.displayAmount}`
                        : 'N/A'}
                    </Typography>
                  </Grid>
                  <Stack direction={'row'} gap={1} width="100%">
                    <StyledViewContractButton
                      variant="outlined"
                      startIcon={<VisibilityIcon />}
                      onClick={() => {
                        setSelectedContract(contract);
                        setDrawer(true);
                      }}
                      fullWidth
                    >
                      View
                    </StyledViewContractButton>
                    <RoundButton
                      color="primary"
                      variant="outlined"
                      startIcon={<MessageIcon />}
                      onClick={() => navigate(`/workroom/${contract.chatThreadId}`)}
                      fullWidth
                    >
                      Message
                    </RoundButton>
                  </Stack>
                </Grid>
              );
            })}

          {!contracts ||
            (contracts.length == 0 && (
              <Grid item container>
                <Typography variant="body1" color={theme.palette.grey[500]}>
                  No contracts available
                </Typography>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <CircularProgress color="primary" />
        </Grid>
      )}
    </Grid>
  );
};
