import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { RoundButton } from 'components/common/Button/RoundButton';
import CloseIcon from '@mui/icons-material/Close';

interface InactiveContractContentProps {
  upgradeFunc: () => void;
  handleConfirmDialogClose: () => void;
  headingText: string;
  contentText: JSX.Element;
  skipFunc?: () => void;
}

export default function InactiveContractContent({
  upgradeFunc,
  handleConfirmDialogClose,
  headingText,
  contentText,
  skipFunc,
}: InactiveContractContentProps) {
  return (
    <React.Fragment>
      <StyledDialogTitle>
        <Typography variant="h6" color="grey.900">
          {headingText}
        </Typography>
        <IconButton onClick={() => handleConfirmDialogClose()} sx={{ marginRight: '-12px' }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>{contentText}</StyledDialogContent>
      <StyledDialogActions>
        {skipFunc && (
          <RoundButton onClick={skipFunc} variant="outlined" fullWidth>
            Continue without protection
          </RoundButton>
        )}
        <RoundButton onClick={upgradeFunc} variant="contained" autoFocus>
          Upgrade
        </RoundButton>
      </StyledDialogActions>
    </React.Fragment>
  );
}
