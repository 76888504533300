import { memo, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import ReactPlayer from 'react-player';
import { isiOS } from 'utils/device';

interface VideoPlayerProps {
  url: string;
  playing: boolean;
  muted: boolean;
  className?: string;
  thumbnailUrl?: string;
  aspectRatio?: string;
  hlsStartLevel?: number;
  controls: boolean;
  onPlay?: () => void;
}

interface StyledMediaProps {
  aspectRatio?: string;
}

const StyledVideo = styled('video', {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'aspectRatio',
})<StyledMediaProps>(({ aspectRatio }) => ({
  width: '100%',
  display: 'block',
  objectFit: 'contain',
  aspectRatio: aspectRatio,
  backgroundColor: 'black',
  height: !aspectRatio ? '100%' : undefined,
}));

const StyledPlayer = styled(ReactPlayer, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'aspectRatio',
})<StyledMediaProps>(({ aspectRatio }) => ({
  video: {
    width: '100%',
    display: 'block',
    objectFit: 'contain',
    aspectRatio: aspectRatio,
    backgroundColor: 'black',
    height: !aspectRatio ? '100%' : undefined,
  },
}));

const StyledImage = styled('img', {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'aspectRatio',
})<StyledMediaProps>(({ theme, aspectRatio }) => ({
  width: '100%',
  display: 'block',
  backgroundColor: theme.palette.common.black,
  objectFit: 'contain',
  height: '100%',
  aspectRatio: aspectRatio,
}));

const MemoizedStyledVideo = memo(StyledVideo);
const MemoizedStyledImage = memo(StyledImage);

export default function VideoPlayer({
  url,
  playing,
  muted,
  className,
  thumbnailUrl,
  aspectRatio,
  hlsStartLevel,
  controls,
  onPlay,
}: VideoPlayerProps): JSX.Element {
  const videoElRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<ReactPlayer | null>(null);
  const [firstPlay, setFirstPlay] = useState(thumbnailUrl === undefined);
  const [hasHlsLoaded, setHasHlsLoaded] = useState<boolean>(false);

  const playPause = (): void => {
    if (videoElRef?.current && playing && videoElRef?.current.paused) {
      videoElRef.current.play();
    } else if (videoElRef?.current && !playing && !videoElRef?.current.paused) {
      videoElRef.current.pause();
    }
  };

  const handlePlay = () => {
    if (playerRef.current !== null && !hasHlsLoaded) {
      const hlsPlayer = playerRef.current.getInternalPlayer('hls')?.startLoad(-1);

      if (!hlsPlayer) return;

      setHasHlsLoaded(true);
    }
    if (onPlay) {
      onPlay();
    }
  };

  useEffect(() => {
    if (playing) {
      setFirstPlay(true);
    }
    playPause();
  }, [playing]);

  useEffect(() => {
    playPause();
  }, [firstPlay]);

  return (
    <>
      {url.startsWith(process.env.REACT_APP_BUNNY_VIDEO_CDN_URL ?? '') && !isiOS() ? (
        <StyledPlayer
          url={url}
          ref={playerRef}
          controls={controls}
          muted={muted}
          loop
          width="100%"
          height="100%"
          playing={playing}
          onPlay={() => handlePlay()}
          aspectRatio={aspectRatio}
          config={{
            file: {
              attributes: {
                preload: 'metadata',
                className: className,
                playsInline: true,
                disableRemotePlayback: true,
                poster: thumbnailUrl !== undefined ? thumbnailUrl : undefined,
                autoPlay: false,
              },
              forceHLS: true,
              hlsOptions: {
                autoStartLoad: false,
                maxLoadingDelay: 0.25,
                startLevel: hlsStartLevel ? hlsStartLevel : 1,
                maxBufferSize: 10 * 1000 * 1000,
              },
            },
          }}
        />
      ) : (
        <>
          {!firstPlay ? (
            <MemoizedStyledImage
              className={className}
              alt="User image"
              src={thumbnailUrl}
              loading="eager"
              aspectRatio={aspectRatio}
            />
          ) : (
            <MemoizedStyledVideo
              ref={videoElRef}
              disableRemotePlayback
              muted={muted}
              playsInline
              loop
              controls={controls}
              src={url}
              controlsList="nodownload"
              className={`${className}`}
              preload="metadata"
              aspectRatio={aspectRatio}
              onPlay={handlePlay}
            />
          )}
        </>
      )}
    </>
  );
}
