import { createProductCheckout } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';

type UseSubscriptionSessionReturn = {
  createSubscriptionSession: () => void;
};

export const useSubscriptionSession = (
  showProgress: (show?: boolean | undefined) => void,
): UseSubscriptionSessionReturn => {
  const createSubscriptionSession = () => {
    showProgress(true);
    createProductCheckout(`${window.location.href}`)
      .then(res => {
        window.location.href = res;
      })
      .catch(error => {
        showError(error);
        showProgress(false);
      });
  };

  return { createSubscriptionSession };
};
