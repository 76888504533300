import { Box, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { UserAvatar } from 'components/common/UserAvatar';
import { IConnectionPartcipant } from 'global/interfaces/connection';
import { useNavigate } from 'react-router-dom';

interface IUserBasicInfoProps {
  user?: IConnectionPartcipant;
}

interface StyledAvatarProps {
  isLarge?: boolean;
}

const StyledUserAvatar = styled(UserAvatar, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isLarge',
})<StyledAvatarProps>(({ theme, isLarge }) => ({
  height: isLarge ? '38px' : '28px',
  width: isLarge ? '38px' : '28px',
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[500],
}));

const StyledImageBox = styled(Box, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isLarge',
})<StyledAvatarProps>(({ isLarge }) => ({
  height: isLarge ? '38px' : '28px',
  width: isLarge ? '38px' : '28px',
  position: 'relative',
  borderRadius: '8px',
}));

const StyledStack = styled(Stack)(() => ({
  cursor: 'pointer',
}));

export default function UserBasicInfo({ user }: IUserBasicInfoProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleUserClick = () => {
    navigate(`/users/${user?.userId}`);
  };

  return (
    <Stack direction="row" gap={user?.title !== undefined && user?.title?.length > 0 ? 1.5 : 1.0} alignItems="center">
      <StyledImageBox onClick={handleUserClick} isLarge={user?.title !== undefined && user?.title?.length > 0}>
        <StyledUserAvatar
          userId={user?.userId}
          displayName={user?.displayName}
          isLarge={user?.title !== undefined && user?.title?.length > 0}
        />
      </StyledImageBox>

      <StyledStack onClick={handleUserClick}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant={isMobileScreen ? 'subtitle1' : 'subtitle2'} textTransform="capitalize">
            {user?.displayName}
          </Typography>
        </Stack>

        <Typography variant="body2">{user?.title}</Typography>
      </StyledStack>
    </Stack>
  );
}
