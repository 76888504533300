import { useLocalization } from 'global/hooks/useLocalization';
import ActionBox from './ActionBox';
import EmailInviteButton from 'components/network/EmailInviteButton';

export default function ActionInvite() {
  const { localCurrencySymbol } = useLocalization(19.99);
  return (
    <ActionBox
      title="Invite someone"
      body={`Invite a client or freelancer and get a ${localCurrencySymbol}20 reward when you complete your first contract with them.`}
    >
      <EmailInviteButton />
    </ActionBox>
  );
}
