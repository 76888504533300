import { AuthContext } from 'contexts/AuthContext';
import { useContext, useState } from 'react';
import { createProductCheckout } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import IApiError from 'global/interfaces/api';
import PricingTable from 'components/common/Subscription/PricingTable';
import { StyledCTALightButtton } from 'components/common/Button/CTAs';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';

export default function InactiveSubscription() {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleCheckout = () => {
    setLoading(true);
    createProductCheckout()
      .then((res: string) => {
        window.location.href = res;
      })
      .catch((e: IApiError) => {
        showError(e);
        setLoading(false);
      });
  };

  return (
    <>
      <Typography variant={'body1'} fontSize={{ xs: '16px', md: '18px' }} mt={isMobileScreen ? 2 : 4}>
        You are currently on the free plan. Plans run month-to-month and can be upgraded/downgraded anytime. For more
        info on billing please scroll down to the FAQs.
      </Typography>
      <Box mt={isMobileScreen ? 3 : 6}>
        <PricingTable
          isSeller={authContext.user?.isSellingServices ?? false}
          currencySybmol={localCurrencySymbol}
          premPrice={price}
          freeBgColor="#e3c094"
          freeCTA={
            <>
              <StyledCTALightButtton variant="contained" disabled>
                Your Plan
              </StyledCTALightButtton>
            </>
          }
          premCTA={
            <>
              <StyledCTALightButtton
                variant="contained"
                loading={loading}
                onClick={() => {
                  handleCheckout();
                }}
                sx={{ backgroundColor: 'white !important' }}
              >
                Upgrade Now
              </StyledCTALightButtton>
            </>
          }
        />
      </Box>
    </>
  );
}
