import { Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IFrontUserBase } from 'global/interfaces/user';
import { useState } from 'react';
import { VerticalCardSkeleton } from 'components/common/Skeleton/VerticalCardSkeleton';
import { VirtualizedGrid, VirtualizedGridItemProps } from 'components/common/VirtualizedGrid';
import CardWrapper from 'components/common/CardWrapper';

const StyledTitleGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: '0',
  paddingRight: '0px',
  marginBottom: '18px',

  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
  },
}));

interface IUserListProps {
  users: IFrontUserBase[];
  isLoading: boolean;
  onNextPage: () => void;
  activeUserId: string;
  setActiveUser: (userId: string, isActive: boolean) => void;
}

export const UserList = ({ users, isLoading, onNextPage, activeUserId, setActiveUser }: IUserListProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [muted, setMuted] = useState(true);

  return (
    <Grid container item xs={12} mt={5}>
      {isLoading ? (
        <Grid item xs={12}>
          <VerticalCardSkeleton />
        </Grid>
      ) : (
        <>
          {!isMobile && (
            <StyledTitleGrid>
              <Typography variant={'h4'} color={'grey.800'} textAlign={'left'}>
                Search Results
              </Typography>
            </StyledTitleGrid>
          )}
          <Grid container rowSpacing={2.5} minHeight={'calc(100svh - 400px)'} pb={5}>
            {!users.length && (
              <Typography
                variant="body1"
                color={theme.palette.grey[500]}
                pl={{ xs: 0, md: '12px' }}
                mt={{ xs: 0, md: '24px' }}
              >
                No freelancers found.
              </Typography>
            )}

            {users.length > 0 && (
              <VirtualizedGrid
                items={users}
                itemHeight={250}
                itemMinWidth={300}
                activeUserId={activeUserId}
                muted={muted}
                renderItem={(props: VirtualizedGridItemProps<any>) => (
                  <CardWrapper
                    {...props}
                    muted={muted}
                    setMuted={setMuted}
                    onNextPage={onNextPage}
                    usersLength={users.length}
                    activeUserId={activeUserId}
                    setActiveUser={setActiveUser}
                  />
                )}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
