import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { IContract, ContractType, RecurrenceType, IContractDisplay } from 'global/interfaces/contract';
import { getContract, updateContract } from 'services/contractService';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';

import { styled, useTheme } from '@mui/material';
import { SwipeableContractDrawer } from 'components/common/Contract/SwipeableContractDrawer';
import { LoadingButton } from '@mui/lab';
import { getCurrencySymbol } from 'components/common/Select/CountrySelect';
import ContractPaymentButtons from 'components/common/Contract/contractPaymentButtons';

interface IChatMessagePaymentProps extends IChatMessageDisplay {
  currentUserId?: string;
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '12px 18px',
  borderRadius: theme.spacing(6),
  height: '35px',
}));

export default function ChatMessagePayment(props: IChatMessagePaymentProps): JSX.Element {
  const [contractDrawerOpen, setContractDrawerOpen] = React.useState<boolean>(false);
  const [contract, setContract] = React.useState<IContract>();

  const theme = useTheme();

  const viewContract = (contractId: string) => {
    getContract(contractId)
      .then((res: IContractDisplay) => {
        updateContract(res);
        setContract(res);
        setContractDrawerOpen(true);
      })
      .catch((err: IApiError) => {
        showError(err);
      });
  };

  const currencySymbol: string = getCurrencySymbol(props.contract?.currency);

  return (
    <React.Fragment>
      {props.contract?.buyerUserId !== props.currentUserId &&
        (props.contract?.contractType === null || props.contract?.contractType === ContractType.FixedPrice) && (
          <StyledTypography variant="body2">
            Payment has been requested for the fixed price contract{' '}
            <span
              style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
              onClick={() => viewContract(props.contract!.contractId)}
            >
              {props.contract?.name}
            </span>{' '}
            for the amount of {currencySymbol}
            {props.contract?.amount}. The contract will be activated when payment is received.
          </StyledTypography>
        )}
      {props.contract?.buyerUserId !== props.currentUserId &&
        props.contract?.contractType === ContractType.Retainer &&
        (props.contract?.firstPayment == null || props.contract?.firstPayment) && (
          <StyledTypography variant="body2">
            Payment has been requested for the retainer contract{' '}
            <span
              style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
              onClick={() => viewContract(props.contract!.contractId)}
            >
              {props.contract?.name}
            </span>{' '}
            for the amount of {currencySymbol}
            {props.contract?.amount}
            {' per '}
            {props.contract.recurrence === RecurrenceType.Monthly ? 'month. ' : 'week. '}The contract will be activated
            when payment is received.
          </StyledTypography>
        )}

      {props.contract?.buyerUserId !== props.currentUserId &&
        props.contract?.contractType === ContractType.Retainer &&
        props.contract?.firstPayment != null &&
        !props.contract?.firstPayment && (
          <StyledTypography variant="body2">
            {props.contract?.recurrence} payment has been requested for the retainer contract{' '}
            <span
              style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
              onClick={() => viewContract(props.contract!.contractId)}
            >
              {props.contract?.name}
            </span>{' '}
            for the amount of {currencySymbol}
            {props.contract?.amount}.
          </StyledTypography>
        )}
      {props.contract?.buyerUserId === props.currentUserId &&
        (props.contract?.contractType === null || props.contract?.contractType === ContractType.FixedPrice) && (
          <StyledTypography variant="body2">
            Proceed to make payment for the fixed price contract{' '}
            <span
              style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
              onClick={() => viewContract(props.contract!.contractId)}
            >
              {props.contract?.name}
            </span>{' '}
            for the amount of {currencySymbol}
            {props.contract?.amount}
            {'. '}
            Your payment will be held in escrow and released as agreed in the terms. The contract will be activated when
            payment is received.
          </StyledTypography>
        )}

      {props.contract?.buyerUserId === props.currentUserId &&
        props.contract?.contractType === ContractType.Retainer &&
        (props.contract?.firstPayment == null || props.contract?.firstPayment) && (
          <StyledTypography variant="body2">
            Proceed to make payment for the retainer contract{' '}
            <span
              style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
              onClick={() => viewContract(props.contract!.contractId)}
            >
              {props.contract?.name}
            </span>{' '}
            for the amount of {currencySymbol}
            {props.contract?.amount}
            {' per '}
            {props.contract.recurrence === RecurrenceType.Monthly ? 'month. ' : 'week. '}Your funds will be held in
            escrow and released on the schedule agreed in the terms. Please note that if problems occur, we can only
            assist while the funds are still in escrow. The contract will be activated when payment is received.
          </StyledTypography>
        )}

      {props.contract?.buyerUserId === props.currentUserId &&
        props.contract?.contractType == ContractType.Retainer &&
        props.contract?.firstPayment != null &&
        !props.contract?.firstPayment && (
          <StyledTypography variant="body2">
            Proceed to make the {props.contract?.recurrence?.toLowerCase()} payment for the retainer contract{' '}
            <span
              style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
              onClick={() => viewContract(props.contract!.contractId)}
            >
              {props.contract?.name}
            </span>{' '}
            for the amount of {currencySymbol}
            {props.contract?.amount}. Your funds will be held in escrow and released on the schedule agreed in the
            terms. Please note that if problems occur, we can only assist while the funds are still in escrow.
          </StyledTypography>
        )}
      {props.contract?.buyerUserId === props.currentUserId && props.contract !== undefined && !props.actioned && (
        <ContractPaymentButtons
          contractId={props.contract.contractId}
          contractName={props.contract.name}
          firstPayment={props.contract?.firstPayment ?? true}
          contractType={props.contract.contractType}
          recurrence={props.contract?.recurrence}
          chatThreadId={props.chatThreadId}
        />
      )}
      {props.contract?.buyerUserId === props.currentUserId && props.actioned && (
        <Typography variant="caption" color="grey.500" fontStyle="italic" fontWeight={600}>
          You have already made payment.
        </Typography>
      )}

      {contract && (
        <SwipeableContractDrawer contract={contract} drawer={contractDrawerOpen} setDrawer={setContractDrawerOpen} />
      )}
    </React.Fragment>
  );
}
