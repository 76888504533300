import { FileType } from 'global/enums/fileTypes';
import { Language } from 'global/enums/language';
import { LanguageProficiency } from 'global/enums/languageProficiency';
import { VettingStatus } from 'global/enums/vettingStatus';
import { IBuyerProfile, IEditableUser, IUser, IWorkHistory } from 'global/interfaces/user';
import { array, boolean, mixed, number, object, SchemaOf, string } from 'yup';
import CategoryLookups from 'json/CategoryLookups.json';
import { getSupportedMediaMimes } from 'global/Constants/SupportedFileTypes';
import { countries, fallbackContractCountry, getCurrencySymbol } from 'components/common/Select/CountrySelect';
import { notableClientValidation } from './notableClient';

export const workHistoryValidation: SchemaOf<IWorkHistory> = object({
  company: string().required('Company is required').max(50, 'Maximum of 50 characters allowed'),
  title: string().required('Profile title is required').max(50, 'Maximum of 50 characters allowed'),
  fromMonth: number().not([0], 'From month is required').required('From month is required'),
  fromYear: number().not([0], 'From year is required').required('From year is required'),
  toMonth: number()
    .defined()
    .when('isPresentPosition', {
      is: false,
      then: number().not([0], 'To month is required').required('To month is required'),
    }),
  toYear: number()
    .defined()
    .when('isPresentPosition', {
      is: false,
      then: number().not([0], 'To year is required').required('To year is required'),
    }),
  isPresentPosition: boolean().defined(),
});

const commonFields = {
  profileImage: object()
    .shape({
      id: string().required(),
      directory: string().notRequired(),
      name: string().notRequired(),
      type: mixed<FileType>().notRequired(),
      extension: string().notRequired(),
      isProcessed: boolean().notRequired(),
    })
    .nullable()
    .when('isDraftSave', {
      is: true,
      then: object().nullable().notRequired(),
      otherwise: object().nullable().required('Profile image is required.'),
    }),
  title: string()
    .trim()
    .matches(/^[a-zA-Z0-9\s/,|&@-]+$/, 'Title cannot contain special characters')
    .max(60, 'Maximum of 60 characters allowed')
    .nullable()
    .when('isDraftSave', {
      is: true,
      then: string().nullable().notRequired(),
      otherwise: string().min(3, 'Minimum of 2 characters needed').required('Title is required').nullable(),
    }),
};

export const editUserValidation: SchemaOf<IEditableUser> = object({
  ...commonFields,
  isSellingServices: boolean().defined(),
  email: string().required(),
  firstName: string()
    .required('First name is required')
    .trim()
    .min(2, 'Minimum of 2 characters needed')
    .max(30, 'Maximum of 30 characters allowed'),
  lastName: string()
    .required('Last name is required')
    .trim()
    .min(2, 'Minimum of 2 characters needed')
    .max(30, 'Maximum of 30 characters allowed'),
  country: string().required('Country is required'),
  countryEditable: boolean().required(),
  bio: string()
    .max(2500, 'Maximum of 2500 characters allowed')
    .trim()
    .nullable()
    .notRequired()
    .when(['isSellingServices', 'isDraftSave', 'needsVettingValidation'], {
      is: (isSellingServices: boolean, isDraftSave: boolean, needsVettingValidation: boolean) =>
        isSellingServices && !isDraftSave && needsVettingValidation,
      then: string().min(100, 'Minimum of 100 characters required').required('Bio is required').nullable(),
    }),

  mainCategory: string()
    .notRequired()
    .nullable()
    .when(['isSellingServices', 'isDraftSave', 'needsVettingValidation'], {
      is: (isSellingServices: boolean, isDraftSave: boolean, needsVettingValidation: boolean) =>
        isSellingServices && !isDraftSave && needsVettingValidation,
      then: string().required('Main category is required').nullable(),
    }),
  secondCategory: string().notRequired().nullable(),
  skills: array(
    object({
      id: string().notRequired(),
      value: string().trim().min(2, 'Skill should contain at least 2 characters').required(),
    }),
  )
    .defined()
    .when(['isSellingServices', 'isDraftSave', 'needsVettingValidation'], {
      is: (isSellingServices: boolean, isDraftSave: boolean, needsVettingValidation: boolean) =>
        isSellingServices && !isDraftSave && needsVettingValidation,
      then: array().required().min(3, 'You must select at least 3 skills').max(8, 'Maximum of 8 skills are allowed'),
    }),
  languages: array(
    object({
      language: mixed<Language>().required(),
      proficiency: mixed<LanguageProficiency>().required(),
    }),
  )
    .defined()
    .when(['isSellingServices', 'isDraftSave', 'needsVettingValidation'], {
      is: (isSellingServices: boolean, isDraftSave: boolean, needsVettingValidation: boolean) =>
        isSellingServices && !isDraftSave && needsVettingValidation,
      then: array()
        .min(1, 'You must select at least 1 language')
        .test({
          name: 'uniq-languages',
          message: 'Languages should be unique.',
          test: languages =>
            languages ? languages.map(x => x.language).length === new Set(languages.map(x => x.language)).size : false,
        }),
    }),
  portfolioFiles: array(
    object({
      id: string().required(),
      directory: string().required(),
      path: string().required(),
      name: string().required('File name is required.'),
      type: mixed<FileType>().required(),
      extension: string().required(),
      isProcessed: boolean().required(),
    }),
  )
    .when(['isSellingServices', 'isDraftSave', 'mainCategory', 'needsVettingValidation'], {
      is: (isSellingServices: boolean, isDraftSave: boolean, mainCategory: string, needsVettingValidation: boolean) =>
        isSellingServices &&
        !isDraftSave &&
        needsVettingValidation &&
        CategoryLookups.filter(f => !f.isVisual && f.id === mainCategory).length === 1,
      then: array().test({
        name: 'min-1-feat',
        message: 'Upload at least 1 PDF to showcase your skills and experience.',
        test: files => (files ? files.length >= 1 && files.some(f => f.type === FileType.Pdf) : false),
      }),
    })
    .when(['isSellingServices', 'isDraftSave', 'mainCategory', 'needsVettingValidation'], {
      is: (isSellingServices: boolean, isDraftSave: boolean, mainCategory: string, needsVettingValidation: boolean) =>
        isSellingServices &&
        !isDraftSave &&
        needsVettingValidation &&
        CategoryLookups.filter(f => f.isVisual && f.id === mainCategory).length === 1,
      then: array().test({
        name: 'min-5-im',
        message: 'Upload at least 5 images or videos/audio to showcase your skills and experience.',
        test: files => {
          const mediaFiles = files?.filter(f => getSupportedMediaMimes().some(s => s.startsWith(f.type.toLowerCase())));

          return mediaFiles ? mediaFiles.length >= 5 : false;
        },
      }),
    }),
  workHistories: array(workHistoryValidation).when(['isSellingServices', 'isDraftSave', 'needsVettingValidation'], {
    is: (isSellingServices: boolean, isDraftSave: boolean, needsVettingValidation: boolean) =>
      isSellingServices && !isDraftSave && needsVettingValidation,
    then: array().optional(),
  }),
  vetting: object({
    status: mixed<VettingStatus>().required(),
    submittedOn: string().notRequired().nullable(),
    viewedOn: string().notRequired().nullable(),
  }).notRequired(),
  links: array()
    .of(string().trim().max(1000, 'Maximum of 1000 characters are allowed').default('').defined())
    .when(['isSellingServices', 'isDraftSave', 'needsVettingValidation'], {
      is: (isSellingServices: boolean, isDraftSave: boolean, needsVettingValidation: boolean) =>
        isSellingServices && !isDraftSave && needsVettingValidation,
      then: array()
        .max(3, 'Provide up to 3 links')
        .test({
          name: 'min-1-link',
          message: 'Enter at least 1 link',
          test: links => (links ? links.filter(l => l.trim().length > 0).length >= 1 : false),
        }),
    }),
  hourlyRate: number()
    .notRequired()
    .nullable()
    .when(['isSellingServices', 'isDraftSave', 'needsVettingValidation'], {
      is: (isSellingServices: boolean, isDraftSave: boolean, needsVettingValidation: boolean) =>
        isSellingServices && !isDraftSave && needsVettingValidation,
      then: number()
        .not([0], 'Hourly rate is required')
        .required('Hourly rate is required')
        .nullable()
        .test({
          name: 'min-hourly-rate',
          test: function (hourlyRate: number) {
            const matchinCountry = countries.find(c => c.currency === this.parent.currency);
            const currencySymbol = getCurrencySymbol(matchinCountry?.currency);
            const minLocalRate = 20;

            if (hourlyRate < minLocalRate) {
              return this.createError({
                message: `The minimum hourly rate must be at least ${
                  currencySymbol ?? fallbackContractCountry.currencySymbol
                }${minLocalRate}`,
              });
            }

            return true;
          },
        }),
    }),
  fixedPackages: array(
    object({
      price: number().notRequired().nullable(),
      deliverables: array().notRequired(),
      revisions: number().notRequired().nullable(),
      deliveryMethods: number().notRequired().nullable(),
    }),
  ).notRequired(),
  isUploading: boolean().required().isFalse('Please wait for files to finish uploading'),
  isSubmittingForVetting: boolean().notRequired(),
  needsVettingValidation: boolean().notRequired(),
  isDraftSave: boolean().notRequired(),
  hideLastName: boolean(),
  currency: string().nullable().optional(),
  notableClients: array(notableClientValidation)
    .when(['isSellingServices', 'isDraftSave', 'needsVettingValidation'], {
      is: (isSellingServices: boolean, isDraftSave: boolean, needsVettingValidation: boolean) =>
        isSellingServices && !isDraftSave && needsVettingValidation,
      then: array().optional(),
    })
    .max(6, 'Notable clients must have less than or equal to 6 items'),
});

export const validateBasicUserInfo = async (user: IUser) => {
  const isValidTitle = await editUserValidation.fields.title.isValid(user.title);
  const isValidImage = await editUserValidation.fields.profileImage.isValid(user.profileImage);
  return isValidTitle && isValidImage;
};

export const editBuyerValidation: SchemaOf<IBuyerProfile> = object({
  ...commonFields,
});
