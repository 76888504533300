import React from 'react';
import { IConfirmContractDialogProps } from './ConfirmContractDialog';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { DialogContentText, Typography } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';

export function ActivePlan({
  contentText,
  handleConfirmDialogClose,
  handleClick,
}: IConfirmContractDialogProps & { contentText: JSX.Element }) {
  return (
    <React.Fragment>
      <StyledDialogTitle>
        <Typography variant="h6" color="grey.900">
          Confirm
        </Typography>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <DialogContentText>{contentText}</DialogContentText>
      </StyledDialogContent>
      <StyledDialogActions>
        <RoundButton onClick={handleConfirmDialogClose} variant="outlined">
          Cancel
        </RoundButton>
        <RoundButton onClick={handleClick} variant="contained" autoFocus>
          Continue
        </RoundButton>
      </StyledDialogActions>
    </React.Fragment>
  );
}
