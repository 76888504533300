import {
  countries,
  fallbackContractCountry,
  fallbackCurrency,
  getCurrencyDetails,
} from 'components/common/Select/CountrySelect';

export function getUsdRateForCountry(countryCode: string | undefined): number {
  return getCurrencyDetails(countries.find(c => c.code === countryCode)?.currency ?? fallbackCurrency.currency)
    .conversionRate;
}

export function convertToViewerCurrency(rateInUsd: number, viewerCountryCode: string): number {
  if (viewerCountryCode === fallbackContractCountry.code) {
    return rateInUsd;
  }

  const viewerCountryConversionRate: number = getUsdRateForCountry(viewerCountryCode);
  const rateInViewerCurrency = Math.floor(rateInUsd / viewerCountryConversionRate);
  return rateInViewerCurrency;
}

export function LocalToUsd(rateInLocalCurrency: number, viewerCountryCode: string): number {
  if (viewerCountryCode === fallbackContractCountry.code) {
    return rateInLocalCurrency;
  }

  const viewerCountryConversionRate: number = getUsdRateForCountry(viewerCountryCode);
  const rateInViewerCurrency = Math.ceil(rateInLocalCurrency * viewerCountryConversionRate);
  return rateInViewerCurrency;
}

export function getBasicPlanPriceFromCurrency(currencySymbol: string): number {
  const price = currencySymbol == '£' ? 14.99 : currencySymbol == '€' ? 17.99 : 19.99;
  return price;
}

export function getConciergePricing(): number {
  return 0;
}
