import * as React from 'react';
import Typography from '@mui/material/Typography';
import { IContractAcceptResult, ContractAcceptResultType } from 'global/interfaces/contract';
import { acceptOrDeclineContract } from 'services/contractService';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import Box from '@mui/material/Box';
import { Dialog, DialogContentText, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import { FormTermsAndConditions } from 'components/common/form/FormTermsAndConditions';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { RoundButton } from 'components/common/Button/RoundButton';
import { LoadingButton } from '@mui/lab';
import useProgressBar from 'global/hooks/useProgressBar';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddPayoutDetails from './AddPayoutDetails';
import { getPayout } from 'services/payoutService';
import { IStripeConnect } from 'global/interfaces/payout';
import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import InactiveContractContent from './ConfirmDialog/InactiveContractContent';
import { useSubscriptionSession } from 'global/hooks/useSubscription';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { useLocalization } from 'global/hooks/useLocalization';

interface IContractAcceptButtonProps {
  contractId: string;
  contractName: string;
  largeWidth?: number;
  onCompletion?: () => void;
  returnUrl: string;
  chatThreadId: string;
}

const StyledFormTermsAndConditions = styled(FormTermsAndConditions)(() => ({
  marginBottom: '8px',
}));

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '12px 18px',
  borderRadius: theme.spacing(6),
  height: '35px',
}));

export default function ContractAcceptButtons(props: IContractAcceptButtonProps): JSX.Element {
  const authContext = useContext(AuthContext);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<IContractAcceptResult | undefined>(undefined);

  const [termsAccepted, setTermsAccepted] = React.useState<boolean>();
  const [isPayoutAdded, setIsPayoutAdded] = React.useState<boolean>(true);

  const [progress, showProgress] = useProgressBar();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);

  const handleAcceptClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    if (termsAccepted) {
      acceptOrDecline(true);
    } else {
      setTermsAccepted(false);
    }
  };

  const handleDeclineClick = (): void => {
    acceptOrDecline(false);
  };

  const acceptOrDecline = (accept: boolean): void => {
    setDialogOpen(false);
    showProgress(true);
    if (accept) {
      getPayout()
        .then((res: IStripeConnect) => {
          const hasPayout = res?.payoutsEnabled && res?.ordersEnabled;
          setIsPayoutAdded(hasPayout);
          if (hasPayout) {
            if (!authContext.user?.subscriptionActive && !dialogOpen) {
              setDialogOpen(true);
              showProgress(false);
              return;
            }

            executeAcceptOrDecline(true);
          } else {
            showProgress(false);
            setDialogOpen(true);
          }
        })
        .catch(showError);
    } else {
      executeAcceptOrDecline(false);
    }
  };

  const executeAcceptOrDecline = (accept: boolean): void => {
    acceptOrDeclineContract(props.contractId, accept)
      .then((res: IContractAcceptResult) => {
        setResult(res);
        if (res.result === ContractAcceptResultType.Done) {
          showProgress(false);
          if (props.onCompletion) props.onCompletion();
        } else {
          showProgress(false);
          setDialogOpen(true);
        }
      })
      .catch((err: IApiError) => {
        showProgress(false);
        showError(err);
      });
  };

  const handleDialogClose = (): void => {
    setDialogOpen(false);
  };

  const acceptContractOffer = () => {
    setDialogOpen(false);
    showProgress(true);
    executeAcceptOrDecline(true);
  };

  const { createSubscriptionSession } = useSubscriptionSession(showProgress);

  return (
    <>
      <Box>
        <StyledFormTermsAndConditions
          isAccepted={termsAccepted ?? false}
          onChange={(_, checked) => setTermsAccepted(checked)}
          touched={termsAccepted != undefined}
          error={!termsAccepted}
          isSeller={false}
        />
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item xs={6} lg={props.largeWidth ?? 4}>
            <StyledLoadingButton
              variant="contained"
              type="button"
              fullWidth
              size="small"
              onClick={e => handleAcceptClick(e)}
              startIcon={<DoneAllOutlinedIcon />}
            >
              Accept
            </StyledLoadingButton>
          </Grid>

          <Grid item xs={6} lg={props.largeWidth ?? 4}>
            <StyledLoadingButton
              variant={'outlined'}
              type="button"
              size="small"
              fullWidth
              startIcon={<ClearOutlinedIcon />}
              onClick={handleDeclineClick}
            >
              Decline
            </StyledLoadingButton>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="xs" fullScreen={isMobileScreen}>
        {!isPayoutAdded && (
          <AddPayoutDetails
            isPayoutAdded={isPayoutAdded}
            handleClose={handleDialogClose}
            returnUrl={props.returnUrl}
            title="Accept Contract"
            message="Please add your payout details via our partner Stripe prior to accepting contracts."
          />
        )}
        {isPayoutAdded && (
          <>
            {result?.result === ContractAcceptResultType.AlreadyAccepted && (
              <React.Fragment>
                <StyledDialogTitle>
                  <Typography variant="h6" color="grey.900">
                    Already Accepted
                  </Typography>
                </StyledDialogTitle>
                <StyledDialogContent dividers>
                  <DialogContentText>
                    <Typography variant="body1">{`You have already accepted or declined contract ${props.contractName}`}</Typography>
                  </DialogContentText>
                </StyledDialogContent>
                <StyledDialogActions>
                  <RoundButton onClick={handleDialogClose} autoFocus>
                    OK
                  </RoundButton>
                </StyledDialogActions>
              </React.Fragment>
            )}
            {result?.result === ContractAcceptResultType.Cancelled && (
              <React.Fragment>
                <StyledDialogTitle>
                  <Typography variant="h6" color="grey.900">
                    Cancelled
                  </Typography>
                </StyledDialogTitle>
                <StyledDialogContent dividers>
                  <DialogContentText>
                    <Typography variant="body1">{`Contract ${props.contractName} has been cancelled and no longer requires acceptance.`}</Typography>
                  </DialogContentText>
                </StyledDialogContent>
                <StyledDialogActions>
                  <RoundButton variant="contained" onClick={handleDialogClose} autoFocus>
                    Got It
                  </RoundButton>
                </StyledDialogActions>
              </React.Fragment>
            )}
          </>
        )}
        {isPayoutAdded &&
          !(
            result?.result === ContractAcceptResultType.Cancelled ||
            result?.result === ContractAcceptResultType.AlreadyAccepted
          ) &&
          !authContext.user?.subscriptionActive && (
            <InactiveContractContent
              upgradeFunc={() => {
                setDialogOpen(false);
                createSubscriptionSession();
              }}
              handleConfirmDialogClose={handleDialogClose}
              headingText="Protect your income"
              contentText={
                <Typography variant="body2">
                  We recommend upgrading to premium for {localCurrencySymbol}
                  {price} / month. With premium your income will be protected so if there are issues with the buyer
                  disappearing or refusing to approve completed work then we can assist and release the funds to you if
                  appropriate. You can continue without premium but your income will not be protected.
                </Typography>
              }
              skipFunc={acceptContractOffer}
            />
          )}
      </Dialog>
      {progress}
    </>
  );
}
