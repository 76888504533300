import { useTitle } from 'utils/router';

import { Box, Container, Grid, Stack, styled, Switch, Typography, useMediaQuery, useTheme } from '@mui/material';

import stripelogo from 'assets/images/lps/stripelogo.png';
import innovatelogo from 'assets/images/lps/innovatelogo.png';
import microsoftlogo from 'assets/images/lps/microsoftlogo.png';

import buyerhero from 'assets/images/lps/lowqualityprofiles.webp';
import sellerhero from 'assets/images/lps/lowqualityjobs.webp';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import { faqOptions as buyerFaqs } from '../../components/common/FAQs/buyerData';
import { faqOptions as sellerFaqs } from '../../components/common/FAQs/sellerData';

import NewFAQ from 'components/common/FAQs/NewFAQ';
import SellerMainPoints from './seller/sellerMainPoints';
import BuyerMainPoints from './buyer/buyerMainPoints';

import { AuthContext } from 'contexts/AuthContext';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { LPSectionTypography, StyledLPBox } from 'components/info/LPSection';
import EastIcon from '@mui/icons-material/East';
import PricingTable from 'components/common/Subscription/PricingTable';
import { StyledCTAButtton, StyledCTALightButtton } from 'components/common/Button/CTAs';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 46,
  height: 24,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(21px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(21px)',

      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#6471E6',

        border: '1px solid #6471E6',
      },

      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    borderRadius: 9,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: theme.palette.common.white,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: '#231A14',
    border: '1px solid #231A14',
    boxSizing: 'border-box',
  },
}));

const StyledLogoImage = styled('img')(({ theme }) => ({
  height: '60px',
  [theme.breakpoints.down('lg')]: {
    height: '50px',
  },
  [theme.breakpoints.down('md')]: {
    height: '40px',
  },
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  objectFit: 'contain',
  maxWidth: '580px',
  maxHeight: '700px',

  [theme.breakpoints.up('md')]: {
    paddingLeft: '24px',
  },
}));

interface SwitcherTypographyProps {
  enabled?: boolean;
}

const SwitcherTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'enabled',
})<SwitcherTypographyProps>(({ enabled, theme }) => ({
  fontWeight: 700,
  color: enabled ? theme.palette.common.black : '#231A144D',

  fontSize: '24px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
}));

const HeroHeadlineTypohgraphy = styled(Typography)(({ theme }) => ({
  color: '#101828',
  fontWeight: 700,
  fontFamily: 'Inter',
  letterSpacing: '-3%',
  lineHeight: '128%',

  fontSize: '56px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '32px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
  },
}));

export default function Info() {
  useTitle('Shoutt | Connecting Founders and Freelancers');
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isForFreelancers, setIsForFreelancers] = useState<boolean>(searchParams.has('freelance'));
  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);

  const handleButtonClick = (): void => {
    navigate('/signup');
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsForFreelancers(event.target.checked);
  };

  return (
    <>
      <Box sx={{ backgroundColor: '#F9FAFB' }}>
        <StyledLPBox
          backgroundColor={isForFreelancers ? '#F6F8FE' : '#FEFAEC'}
          isMobile={isMobile}
          pt={{ xs: 5, md: 12 }}
          pb={{ xs: 5, md: 12 }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={{ xs: 4, sm: 4, lg: 2 }} direction={{ xs: 'column-reverse', md: 'row-reverse' }}>
              <Grid item xs={12} md={6} container justifyContent={{ xs: 'center', md: 'flex-end' }}>
                <StyledImage
                  src={buyerhero}
                  loading="eager"
                  style={{ display: isForFreelancers ? 'none' : 'inline' }}
                />
                <StyledImage
                  src={sellerhero}
                  loading="eager"
                  style={{ display: isForFreelancers ? 'inline' : 'none' }}
                />
              </Grid>
              <Grid item xs={12} md={6} container alignItems={'center'}>
                <Stack direction="column" rowGap={3} alignContent={'flex-start'}>
                  <Stack direction="row" spacing={2} alignItems={'center'}>
                    <SwitcherTypography variant="body1" enabled={!isForFreelancers}>
                      FOR HIRING
                    </SwitcherTypography>

                    <AntSwitch checked={isForFreelancers} onChange={handleSwitchChange} />
                    <SwitcherTypography variant="body1" enabled={isForFreelancers}>
                      FOR FREELANCERS
                    </SwitcherTypography>
                  </Stack>
                  <HeroHeadlineTypohgraphy variant="h3">
                    {isForFreelancers
                      ? 'Tired of low quality jobs and high commissions?'
                      : 'Handpicked freelancers for time-strapped founders'}
                  </HeroHeadlineTypohgraphy>
                  <LPSectionTypography>
                    {isForFreelancers
                      ? 'Meet Shoutt, a commission free platform that helps you get discovered by startups that are eager to scale. Simply create a free profile and we’ll get you in front of the right prospects - without any bidding.'
                      : 'Meet Shoutt, a commission-free platform that helps you find and collaborate with vetted freelancers without breaking a sweat. Forget about sifting through low quality proposals and profiles – get results and scale your startup in no time.'}
                  </LPSectionTypography>
                  {!authContext.user && (
                    <Box mt={2}>
                      <StyledCTAButtton variant="contained" onClick={handleButtonClick} endIcon={<EastIcon />}>
                        {isForFreelancers ? 'Apply Now' : 'Get Started'}
                      </StyledCTAButtton>
                    </Box>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </StyledLPBox>
        <Stack
          paddingTop={{ xs: 8, md: 10 }}
          paddingBottom={{ xs: 8, sm: 14 }}
          direction={'column'}
          alignItems="center"
          spacing={{ xs: 4, md: 6 }}
          sx={{ backgroundColor: theme.palette.common.white }}
        >
          <Typography
            variant="body1"
            textAlign={'center'}
            fontFamily={'Inter'}
            fontSize={{ xs: '16px', md: '18px', lg: '20px' }}
          >
            We’re proud to partner with
          </Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 4, md: 8 }} alignItems={'center'}>
            <StyledLogoImage src={microsoftlogo} />
            <StyledLogoImage src={innovatelogo} />
            <StyledLogoImage src={stripelogo} />
          </Stack>
        </Stack>
        {isForFreelancers ? <SellerMainPoints /> : <BuyerMainPoints />}

        <StyledLPBox backgroundColor={'#F8E2D3'} isMobile={isMobile} pt={{ xs: 6, md: 12 }} pb={{ xs: 5, md: 12 }}>
          <Container maxWidth="lg">
            <Typography
              variant="h2"
              fontWeight={700}
              textAlign={isMobile ? 'left' : 'center'}
              fontSize={{ xs: '30px', md: '40px', lg: '56px' }}
              mb={{ xs: 4, md: 8 }}
            >
              Get started today
            </Typography>
            <PricingTable
              isSeller={isForFreelancers}
              currencySybmol={localCurrencySymbol}
              premPrice={price}
              freeCTA={
                <>
                  {!authContext.user && (
                    <StyledCTAButtton
                      variant="contained"
                      sx={{
                        mt: 2,
                        width: '100%',
                      }}
                      onClick={handleButtonClick}
                      endIcon={<EastIcon />}
                    >
                      Get Started
                    </StyledCTAButtton>
                  )}
                </>
              }
              premCTA={
                <>
                  {!authContext.user && (
                    <StyledCTALightButtton
                      variant="contained"
                      sx={{
                        mt: 2,
                        width: '100%',
                      }}
                      onClick={handleButtonClick}
                      endIcon={<EastIcon />}
                    >
                      Get Started
                    </StyledCTALightButtton>
                  )}
                </>
              }
            />
          </Container>
        </StyledLPBox>

        <NewFAQ
          description="Everything you need to know about the product and billing."
          options={isForFreelancers ? sellerFaqs : buyerFaqs}
        />
      </Box>
    </>
  );
}
