import { Box, Stack, styled, Typography } from '@mui/material';
import { getConciergePricing } from 'utils/currency';

export const StyledCheckBox = styled(Box)(() => ({
  background: '#4D86F9',
  borderRadius: '100%',
  width: '22px',
  height: '22px',
  padding: '4px',
  display: 'inline-flex',
  alignItems: 'center',
}));

export const StyledSubHeadingTypography = styled(Typography)(() => ({
  marginLeft: '8px',
  display: 'inline-flex',
  alignItems: 'center',
  color: '#10194C',
}));

export const StyledSmallTypography = styled(Typography)(() => ({
  display: 'flex',
  color: '#4B5563',
  marginLeft: '29px',
  marginTop: '2px',
  fontWeight: '400',
}));

interface Step {
  heading: string;
  text: string;
}

export function getSteps(currencySymbol: string): Step[] {
  const price = getConciergePricing();
  if (price > 0) {
    return [
      {
        heading: 'Send us your project details',
        text: 'Include budget, timeline etc',
      },
      {
        heading: 'We reply within 24 hours',
        text: 'To clarify any further details',
      },
      {
        heading: `We request a flat fee of ${currencySymbol}${price}`,
        text: 'You can pay via a variety of payment methods',
      },
      {
        heading: 'Within 3 days we send you 3 proposals',
        text: 'From the best freelancers in our network for your project',
      },
      {
        heading: 'You choose one and pay on our platform',
        text: 'Transactions with freelancers are 100% commission free',
      },
      {
        heading: 'Satfisfaction guaranteed!',
        text: 'Or we will give you a full refund',
      },
    ];
  } else {
    return [
      {
        heading: 'Send us your project details',
        text: 'Include budget, timeline etc',
      },
      {
        heading: 'We reply within 24 hours',
        text: 'To clarify any further details',
      },
      {
        heading: 'Within 3 days we send you 3 proposals',
        text: 'From the best freelancers in our network for your project',
      },
      {
        heading: 'You choose one and pay on our platform',
        text: 'Transactions with freelancers are 100% commission free',
      },
      {
        heading: 'Protect your payment with the premium plan',
        text: 'If the freelancer disappears then you get a full refund!',
      },
    ];
  }
}

interface ConciergeStepsProps {
  currencySymbol: string;
}

export default function ConciergeSteps({ currencySymbol }: ConciergeStepsProps) {
  const steps = getSteps(currencySymbol);
  return (
    <Stack direction="column" gap="20px" sx={{ paddingRight: '24px' }}>
      {steps.map((step, index) => (
        <Box key={index}>
          <StyledCheckBox>
            <Typography textAlign={'center'} variant="caption" color={'white'} fontWeight={800} pl="3px" pt="1px">
              {index + 1}
            </Typography>
          </StyledCheckBox>
          <StyledSubHeadingTypography variant="subtitle2">{step.heading}</StyledSubHeadingTypography>
          <StyledSmallTypography variant="caption">{step.text}</StyledSmallTypography>
        </Box>
      ))}
    </Stack>
  );
}
