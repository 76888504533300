import { Box, Typography, styled } from '@mui/material';
import { contentList } from './ContentList';

export interface IContainerRefProps {
  containerRef: React.RefObject<HTMLElement>;
}

const StyledBox = styled(Box)(() => ({
  width: '100%',
  overflow: 'hidden',
}));

const BiteBox = styled(Box)(({ theme }) => ({
  width: '94%',
  backgroundColor: theme.palette.grey[100],
  borderRadius: '12px',
  padding: '20px 24px 20px 24px',
  marginTop: '12px',
  height: '265px',

  [theme.breakpoints.up('md')]: {
    marginLeft: '3%',
  },

  [theme.breakpoints.between('sm', 'lg')]: {
    height: '350px',
    width: '98%',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    minHeight: '350px',
    marginBottom: '10px',
  },
}));

const createContentComponent = (contentIndex: number) =>
  function ContentComponent() {
    const content = contentList[contentIndex];
    return (
      <BiteBox>
        <StyledBox>
          {content.paragraphs.map((paragraph, index) => (
            <Typography key={index} variant={paragraph.variant} mt={index == 0 ? 0.5 : 2}>
              {paragraph.text}
            </Typography>
          ))}
        </StyledBox>
      </BiteBox>
    );
  };

export const Referrals = createContentComponent(0);
export const Invite = createContentComponent(1);
export const Marketplace = createContentComponent(2);
export const Contracts = createContentComponent(3);
export const Retainers = createContentComponent(4);
export const FixedPrice = createContentComponent(5);
export const Escrow = createContentComponent(6);

export const BiteSizeContentLength = 7;
