import {
  DialogContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormikProps } from 'formik';
import { ContractViewType, ICreateContractForm, RecurrenceType } from 'global/interfaces/contract';
import { IUser } from 'global/interfaces/user';
import FormSelect from '../Select/FormSelect';
import CyclesLookups from 'json/CyclesLookups.json';
import ReleaseLookups from 'json/ReleaseLookups.json';
import { FormTermsAndConditions } from '../form/FormTermsAndConditions';
import { RoundButton } from '../Button/RoundButton';
import { StyledDialogActions, StyledDialogTitle } from 'components/common/Dialog';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { ChatType, IChatUserThread } from 'global/interfaces/chatThread';
import { Link } from 'react-router-dom';
import { addDays, addMonths, addWeeks } from 'date-fns';
import { getCurrencySelectItems, getCurrencySymbol } from '../Select/CountrySelect';
import { ReceivePackagePriceContainer, StyledAdornment, StyledTextField } from './CreateFixedPriceContract';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { StyledLoadingButton } from 'components/payouts/payoutHandler';

const StyledContentContainer = styled(Grid)(() => ({
  marginTop: '18px',
}));

export const StyledHourlyDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '24px 24px !important',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  [theme.breakpoints.down('sm')]: {
    padding: '20px 16px !important',
    minHeight: 'calc(100svh - 140px)',
  },
}));

export interface IContractHoursProps extends FormikProps<ICreateContractForm> {
  user: IUser | null;
  back: () => void;
  handleCancel: () => void;
  loading: boolean;
  selectedThread: IChatUserThread | undefined;
}

export const getReceiveText = (
  recurrence: RecurrenceType | undefined | null,
  release: number,
  startDate: Date | null | undefined,
): string => {
  if (recurrence === RecurrenceType.Monthly && startDate != null) {
    if (release === 1) {
      return addMonths(startDate, 1).toDateString();
    } else if (release >= 0.5) {
      return addDays(startDate, 14).toDateString();
    } else {
      return addDays(startDate, 7).toDateString();
    }
  } else if (recurrence === RecurrenceType.Weekly && startDate != null) {
    return addWeeks(startDate, 1).toDateString();
  }
  return '';
};

export const CreateRetainerContract: React.FC<IContractHoursProps> = ({
  back,
  handleCancel,
  loading,
  selectedThread,
  ...form
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isOneToOneChat = selectedThread?.type == ChatType.OneToOne;
  const [currencySelectItems] = useState(() => getCurrencySelectItems());
  const recurrenceValues = Object.keys(RecurrenceType).map((k: string) => ({ id: k, label: k }));

  const currencySymbol = getCurrencySymbol(form.values.currency);

  return (
    <>
      <StyledDialogTitle>
        <Stack direction="row" gap={1.5} alignItems="center">
          <IconButton onClick={back} sx={{ marginLeft: '-12px' }}>
            <ArrowBackIcon />
          </IconButton>

          <Typography variant="h6">New Retainer {isOneToOneChat && `for ${selectedThread.displayName}`}</Typography>
        </Stack>
        <Link to={process.env.REACT_APP_CONTRACTS_URL ?? ''} target="_blank">
          <IconButton sx={{ marginRight: '-6px' }}>
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </Link>
      </StyledDialogTitle>

      <StyledHourlyDialogContent dividers>
        <Grid xs={12} item>
          <Typography variant="subtitle1">Basic Information</Typography>
          <Typography variant="body2" mb={1}>
            Enter the recurrence, duration, price and a unique title for the retainer.
          </Typography>
          <Grid container spacing={1} alignItems="top" mt={2} mb={2}>
            <Grid item xs={6}>
              <FormSelect
                items={recurrenceValues}
                label="Recurrence"
                name="recurrence"
                value={form.values.recurrence !== null ? form.values.recurrence : ''}
                onChange={form.handleChange}
                margin="dense"
                error={form.touched.recurrence !== undefined && Boolean(form.errors.recurrence)}
                helpertext={form.touched.recurrence !== undefined ? form.errors.recurrence : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                items={CyclesLookups}
                label={form.values.recurrence === RecurrenceType.Monthly ? 'Duration (Months)' : 'Duration (Weeks)'}
                name="duration"
                value={form.values.duration !== null ? form.values.duration : ''}
                onChange={form.handleChange}
                margin="dense"
                error={form.touched.duration !== undefined && Boolean(form.errors.duration)}
                helpertext={form.touched.duration !== undefined ? form.errors.duration : ''}
              />
            </Grid>

            <Grid item xs={6}>
              <StyledTextField
                type="number"
                label={form.values.recurrence + ' Price'}
                name="price"
                value={form.values.price !== null ? form.values.price : ''}
                margin="dense"
                variant={'outlined'}
                onChange={form.handleChange}
                error={form.touched.price !== undefined && Boolean(form.errors.price)}
                helperText={form.touched.price !== undefined ? form.errors.price : ''}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <StyledAdornment position="start">
                      <FormSelect
                        type="number"
                        name="currency"
                        value={form.values.currency !== null ? form.values.currency : ''}
                        margin="dense"
                        variant={'outlined'}
                        onChange={form.handleChange}
                        error={form.touched.currency !== undefined && Boolean(form.errors.currency)}
                        fullWidth
                        items={currencySelectItems}
                        helpertext={form.touched.currency !== undefined ? form.errors.currency : ''}
                      />
                    </StyledAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="name"
                label="Contract Title"
                placeholder="Designs for ABC Corp"
                variant={'outlined'}
                value={form.values.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.handleChange(e);
                }}
                margin="dense"
                error={form.touched.name !== undefined && Boolean(form.errors.name)}
                helperText={form.touched.name !== undefined ? form.errors.name : ''}
                fullWidth
              />
            </Grid>
          </Grid>
          <Typography variant="subtitle1">Services</Typography>
          <Typography variant="body2" mb={1}>
            {form.values.buySell === ContractViewType.Buy
              ? 'Enter a short description of what services/duties the seller will deliver for the course of the contract.'
              : 'Enter a short description of what services/duties you will deliver for the course of the contract.'}
          </Typography>
          <Grid container spacing={1} alignItems="top" mt={1} mb={2}>
            <Grid item xs={12}>
              <TextField
                label=""
                name="description"
                value={form.values.description !== null ? form.values.description : ''}
                margin="dense"
                variant={'outlined'}
                onChange={form.handleChange}
                error={form.touched.description !== undefined && Boolean(form.errors.description)}
                helperText={form.touched.description !== undefined ? form.errors.description : ''}
                fullWidth
                multiline={true}
                rows={2}
              />
            </Grid>
          </Grid>

          {form.values.recurrence === RecurrenceType.Monthly && (
            <>
              <Typography variant="subtitle1">Payment Release</Typography>
              <Typography variant="body2" mb={1}>
                {form.values.buySell === ContractViewType.Buy
                  ? 'You will pay up front at the start of each period. Please choose when payment will be released to the seller.'
                  : 'Clients pay up front at the start of each period. Please choose when payment will be released to you.'}
              </Typography>
              <Grid container spacing={1} alignItems="top" mt={2} mb={2}>
                <Grid item xs={12}>
                  <FormSelect
                    items={ReleaseLookups}
                    label="Release Schedule"
                    name="release"
                    value={form.values.release !== null ? form.values.release : ''}
                    onChange={form.handleChange}
                    margin="dense"
                    error={form.touched.release !== undefined && Boolean(form.errors.release)}
                    helpertext={form.touched.release !== undefined ? form.errors.release : ''}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {form.values.buySell === ContractViewType.Sell && (
            <ReceivePackagePriceContainer container>
              <Grid container justifyContent="space-between" item xs={12}>
                <Typography variant="subtitle2">{form.values.recurrence + ' '}Contract Total</Typography>
                <Typography variant="subtitle2">
                  {currencySymbol}
                  {form.values.price ? form.values.price.toFixed(2) : ''}
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between" item xs={12}>
                <Link to="https://stripe.com/pricing" target="_blank">
                  <Typography variant="subtitle2" color={theme.palette.primary.dark}>
                    Stripe Fee (approx 1-3%)
                  </Typography>
                </Link>

                <Typography variant="subtitle2">
                  {currencySymbol}
                  {form.values.price ? (form.values.price * 0.02).toFixed(2) : ''}
                </Typography>
              </Grid>

              <Grid container justifyContent="space-between" item xs={12}>
                <Typography variant="subtitle2" fontWeight={700}>
                  Each {form.values.recurrence === RecurrenceType.Monthly ? 'month' : 'week'} you will receive approx{' '}
                </Typography>
                <Typography variant="subtitle2" fontWeight={700}>
                  {currencySymbol}
                  {form.values.price ? (form.values.price * 0.98).toFixed(2) : ''}
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between" item xs={12}>
                <Typography variant="subtitle2">Your first payout will be</Typography>

                <Typography variant="subtitle2">
                  {' '}
                  {' ' + getReceiveText(form.values.recurrence, form.values.release ?? 0, new Date())}
                </Typography>
              </Grid>
            </ReceivePackagePriceContainer>
          )}
          {form.values.buySell === ContractViewType.Buy && (
            <ReceivePackagePriceContainer container>
              <Grid container justifyContent="space-between" item xs={12}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {form.values.recurrence + ' '}Contract Total
                </Typography>
                <Typography variant="subtitle2" fontWeight={700}>
                  {currencySymbol}
                  {form.values.price ? form.values.price.toFixed(2) : ''}
                </Typography>
              </Grid>

              <Grid container justifyContent="space-between" item xs={12}>
                <Typography variant="subtitle2">The seller&apos;s first payout will be</Typography>

                <Typography variant="subtitle2">
                  {' '}
                  {' ' + getReceiveText(form.values.recurrence, form.values.release ?? 0, new Date())}
                </Typography>
              </Grid>
            </ReceivePackagePriceContainer>
          )}
          <StyledContentContainer container>
            <FormTermsAndConditions
              isAccepted={form.values.termsAccepted}
              onChange={form.handleChange}
              touched={form.touched.termsAccepted}
              error={form.errors.termsAccepted}
              isSeller
            />
          </StyledContentContainer>
        </Grid>
      </StyledHourlyDialogContent>

      <StyledDialogActions>
        <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={() => handleCancel()}>
          Cancel
        </RoundButton>
        <StyledLoadingButton
          fullWidth={isMobileScreen}
          loading={loading}
          variant="contained"
          onClick={() => form.handleSubmit()}
        >
          Send
        </StyledLoadingButton>
      </StyledDialogActions>
    </>
  );
};
