import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { editCardDetails, getCurrentUserSubscription, stopCancellation } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';
import { format } from 'date-fns/esm';
import CancelSubscriptionDialog from './cancelSubscriptionDialog';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { UserSubscription } from 'global/interfaces/userSubscription';
import PricingTable from 'components/common/Subscription/PricingTable';
import { useLocalization } from 'global/hooks/useLocalization';
import { StyledCTALightButtton } from 'components/common/Button/CTAs';

export default function ActiveSubscription() {
  const authContext = useContext(AuthContext);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [subscription, setSubscription] = useState<UserSubscription>();
  const theme = useTheme();
  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const cancellationSet: boolean =
    subscription?.subscriptionCancelAtPeriodEnd === true && authContext.user?.subscriptionActive === true;

  const loadSubscription = () => {
    getCurrentUserSubscription()
      .then(res => setSubscription(res))
      .catch(showError);
  };

  const handleStopCancellation = () => {
    setSubscriptionLoading(true);
    stopCancellation()
      .then(() => {
        authContext.refresh().then(() => {
          loadSubscription();
          setSubscriptionLoading(false);
        });
      })
      .catch(e => {
        showError(e);
        setSubscriptionLoading(false);
      });
  };

  const handleEdit = () => {
    setEditLoading(true);
    editCardDetails()
      .then(res => {
        window.location.href = res;
      })
      .catch(e => {
        setEditLoading(false);
        showError(e);
      });
  };

  useEffect(() => {
    loadSubscription();
  }, []);

  return (
    <>
      <Typography variant={'body1'} fontSize={{ xs: '16px', md: '18px' }} mt={isMobileScreen ? 2 : 4}>
        {cancellationSet ? (
          `You have cancelled your premium plan and it will end on ${
            authContext.user && subscription
              ? format(new Date(subscription.subscriptionCurrentPeriodEnd), 'MMMM d, yyyy')
              : 'N/A'
          }. You can continue to use premium features until this date. For more info on billing please scroll down to the FAQs.`
        ) : (
          <>
            You are currently on the premium plan. Your payment card ends in {subscription?.cardLast4}. You can update
            your payment details{' '}
            <span
              style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
              onClick={() => handleEdit()}
            >
              here
            </span>
            . For more info on billing please scroll down to the FAQs.
          </>
        )}
      </Typography>
      <Box mt={isMobileScreen ? 3 : 6}>
        <PricingTable
          isSeller={authContext.user?.isSellingServices ?? false}
          currencySybmol={localCurrencySymbol}
          premPrice={price}
          freeBgColor="#e3c094"
          freeCTA={
            <>
              {!cancellationSet && (
                <StyledCTALightButtton
                  variant="contained"
                  sx={{ backgroundColor: 'white !important' }}
                  onClick={() => setOpen(true)}
                >
                  Downgrade Now
                </StyledCTALightButtton>
              )}
              {cancellationSet && (
                <StyledCTALightButtton variant="contained" sx={{ backgroundColor: 'white !important' }} disabled>
                  Downgrade Pending
                </StyledCTALightButtton>
              )}
            </>
          }
          premCTA={
            <>
              {!cancellationSet && (
                <StyledCTALightButtton variant="contained" disabled={true} sx={{ backgroundColor: 'white !important' }}>
                  Your Plan
                </StyledCTALightButtton>
              )}
              {cancellationSet && (
                <StyledCTALightButtton
                  variant="contained"
                  onClick={() => handleStopCancellation()}
                  loading={subscriptionLoading}
                  disabled={editLoading}
                  sx={{ backgroundColor: 'white !important' }}
                >
                  Continue Subscription
                </StyledCTALightButtton>
              )}
            </>
          }
        />
      </Box>

      <CancelSubscriptionDialog
        open={open}
        handleClose={() => setOpen(false)}
        subscriptionPeriodEnd={subscription?.subscriptionCurrentPeriodEnd}
        loadSubscription={loadSubscription}
      />
    </>
  );
}
