import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useNavigate, Link, useLocation, useSearchParams, useParams } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import * as React from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { ConnectionRestarted, MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import DeleteProfileDialog from 'components/common/DeleteProfileDialog/DeleteProfile';
import Container from '@mui/material/Container';
import logo from './assets/images/logo/Logo.svg';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MailIcon from '@mui/icons-material/Mail';
import Badge from '@mui/material/Badge';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getNumberUnread } from 'services/chatMessageService';
import { showError } from 'utils/errorHandler';
import { UserRole } from 'global/enums/userRole';
import UserSearch from 'components/common/Search/UserSearch';
import { BottomNavigation, BottomNavigationAction, Slide, Stack, Typography, useScrollTrigger } from '@mui/material';
import HeaderBanner from 'HeaderBanner';
import { IChatUnread } from 'global/interfaces/chatMessage';
import { getConnectionsCount, getReceivedInvitationCount } from 'services/connectionService';
import { IConnectionCount } from 'global/interfaces/connection';
import { logout } from 'services/authService';
import { UserAvatar } from 'components/common/UserAvatar';
import { redirectToLogin } from 'utils/router';
import { useContext, useEffect, useState } from 'react';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import { FaviconType, changeFaviconType, getFaviconType } from 'utils/favicon';
import { RoundButton } from 'components/common/Button/RoundButton';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import { ArrowBackIcon } from 'components/icon/ArrowBackIcon';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PeopleIcon from '@mui/icons-material/People';

const StyledAppBar = styled(AppBar)`
  padding: 0;
`;

const Logo = styled('img')(({ theme }) => ({
  height: theme.spacing(4.5),
  marginRight: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    height: theme.spacing(4),
  },
}));

const StyledAvatar = styled(UserAvatar)(({ theme }) => ({
  height: 40,
  width: 40,

  [theme.breakpoints.down('sm')]: {
    height: '30px',
    width: '30px',
    fontSize: '14px',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginRight: theme.spacing(2),

  '&.MuiSvgIcon-root': {
    fontSize: theme.spacing(3),
  },

  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(0),
  },
}));

const ArrowBackIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginLeft: '-10px',
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(() => ({
  minWidth: '50px',
}));

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    font-weight: 500;
  }
`;

interface HideOnScrollProps {
  children: React.ReactElement;
}

function HideOnScroll(props: HideOnScrollProps) {
  const { children } = props;
  const trigger = useScrollTrigger({});

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header = () => {
  const authContext = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteProfileVisible, setIsDeleteProfileVisible] = useState<boolean>(false);
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState<number>(0);
  const [numberOfConnections, setNumberOfConnections] = useState<number>(0);
  const [numberOfInvitations, setNumberOfInvitations] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isAway, updateTitlePrefix, showGlobalNav } = useContext(GlobalStateContext);
  const [searchParams] = useSearchParams();
  const [isBackNavVisible, setIsBackNavVisible] = useState<boolean>(false);
  const urlParams = useParams();
  const { id: userId } = urlParams;

  useEffect(() => {
    const faviconType = getFaviconType();
    if (numberOfUnreadMessages > 0) {
      if (faviconType !== FaviconType.Notification) {
        changeFaviconType(FaviconType.Notification);
      }
      updateTitlePrefix(`(${numberOfUnreadMessages})`);
    } else if (numberOfUnreadMessages == 0) {
      if (faviconType !== FaviconType.Main) {
        changeFaviconType(FaviconType.Main);
      }
      updateTitlePrefix('');
    }
  }, [numberOfUnreadMessages, isAway]);

  const handleButtonClick = (route: string) => () => {
    navigate(route);
  };

  const handleLogoutClick = (): void => {
    logout();
    authContext.refresh().finally(() => {
      navigate('/');
      setAnchorEl(null);
    });
  };

  const handleNavigationClick =
    (route: string) =>
    (e: React.MouseEvent<HTMLElement>): void => {
      e.stopPropagation();
      navigate(route);
      setAnchorEl(null);
    };

  const handleMenu = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleShowDeleteProfile = (): void => {
    setAnchorEl(null);
    setIsDeleteProfileVisible(true);
  };

  const handleCloseDeleteProfile = (): void => {
    setIsDeleteProfileVisible(false);
  };

  const loadNumberUnread = (): void => {
    if (authContext.user) {
      getNumberUnread()
        .then((res: IChatUnread) => {
          setNumberOfUnreadMessages(res.numberUnread);
        })
        .catch(showError);
    }
  };

  const loadPendingInvitations = (): void => {
    if (authContext.user) {
      getReceivedInvitationCount()
        .then((res: IConnectionCount) => {
          setNumberOfInvitations(res.count);
        })
        .catch(showError);
    }
  };

  const loadNumberConnections = (): void => {
    if (authContext.user) {
      getConnectionsCount()
        .then((res: IConnectionCount) => {
          setNumberOfConnections(res.count);
        })
        .catch(showError);
    }
  };

  const navigateBack = (): void => {
    navigate(-1);
  };

  useEffect(() => {
    setIsBackNavVisible(searchParams && searchParams.has('backnav') && searchParams.get('backnav') == 'true');
  }, [searchParams]);

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.NewMessage],
    () => {
      loadNumberUnread();
      if (numberOfConnections < 1) {
        loadNumberConnections();
      }
    },
    [authContext],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.ReadMessages],
    () => {
      loadNumberUnread();
    },
    [authContext],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.UpdateThread],
    () => {
      loadNumberUnread();
    },
    [authContext],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.ConnectionInvite],
    () => {
      loadPendingInvitations();
    },
    [authContext],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.SubscriptionEnded],
    () => {
      authContext.refresh();
    },
    [authContext],
  );

  const loadAll = () => {
    if (!authContext.loading) {
      loadNumberUnread();
      loadNumberConnections();
      loadPendingInvitations();
    }
  };

  useEffect(() => {
    loadAll();
  }, [authContext.user, authContext.loading]);

  useCustomEventListener(
    ConnectionRestarted,
    () => {
      loadAll();
    },
    [authContext],
  );

  const hasAdminRole = authContext.user && authContext.user.roles.indexOf(UserRole.Administrator) > -1;

  return (
    <>
      {showGlobalNav && (
        <HideOnScroll>
          <StyledAppBar position="fixed">
            <Container>
              <Toolbar disableGutters variant="dense">
                <Stack alignItems="center" direction="row">
                  {(!isMobile || !isBackNavVisible || history.length === 0) && (
                    <Stack component={Link} to="/">
                      <Logo alt="Shoutt" src={logo} />
                    </Stack>
                  )}
                  {isMobile && isBackNavVisible && history.length > 0 && (
                    <ArrowBackIconButton onClick={navigateBack} size="small">
                      <ArrowBackIcon />
                    </ArrowBackIconButton>
                  )}
                  {!isMobile && process.env.REACT_APP_PRIMING !== 'true' && (
                    <Stack direction="row">
                      <UserSearch />
                    </Stack>
                  )}

                  {!isMobile && process.env.REACT_APP_PRIMING !== 'true' && (
                    <>
                      <RoundButton
                        aria-label="explore talent"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => navigate('/talent')}
                        sx={{ paddingX: '16px', marginLeft: '10px' }}
                        variant="text"
                      >
                        Explore Talent
                      </RoundButton>

                      {!authContext.user && (
                        <RoundButton
                          aria-label="explore talent"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => navigate('/concierge')}
                          sx={{ paddingX: '16px', marginLeft: '10px' }}
                          variant="text"
                        >
                          Post a Job
                        </RoundButton>
                      )}

                      {location.pathname.toLowerCase() !== '/info' && authContext.user && (
                        <RoundButton
                          color="inherit"
                          sx={{ paddingX: '16px' }}
                          variant="text"
                          onClick={() => navigate('/info')}
                        >
                          Why Shoutt?
                        </RoundButton>
                      )}
                    </>
                  )}
                </Stack>

                {!authContext.loading && (
                  <Stack direction="row" gap={isMobile ? 1 : 0}>
                    {authContext.user && !isMobile && (
                      <>
                        <StyledIconButton
                          color="secondary"
                          size="medium"
                          onClick={handleButtonClick('/network/connections')}
                        >
                          <StyledBadge color="primary" badgeContent={numberOfInvitations}>
                            <PeopleOutlineIcon />
                          </StyledBadge>
                        </StyledIconButton>

                        {(numberOfConnections > 0 || numberOfUnreadMessages > 0) && (
                          <StyledIconButton size="medium" color="secondary" onClick={handleButtonClick('/workroom')}>
                            <StyledBadge color="primary" badgeContent={numberOfUnreadMessages}>
                              <MailOutlineIcon />
                            </StyledBadge>
                          </StyledIconButton>
                        )}
                        {(numberOfConnections > 0 || numberOfUnreadMessages > 0) && (
                          <StyledIconButton color="secondary" size="medium" onClick={handleButtonClick('/contracts')}>
                            <AssignmentOutlinedIcon />
                          </StyledIconButton>
                        )}
                      </>
                    )}

                    {authContext.user && isMobile && location.pathname.toLowerCase() === '/info' && (
                      <Stack alignItems="center" direction="row" spacing={{ xs: 1, sm: 2 }}>
                        <Typography
                          variant="caption"
                          sx={{ position: 'relative', top: '2px', cursor: 'pointer', paddingRight: '8px' }}
                          onClick={() => navigate('/talent')}
                        >
                          Find talent
                        </Typography>
                      </Stack>
                    )}

                    {authContext.user && isMobile && location.pathname.toLowerCase() !== '/info' && (
                      <Stack alignItems="center" direction="row" spacing={{ xs: 1, sm: 2 }}>
                        <>
                          <Typography
                            variant="caption"
                            sx={{ position: 'relative', top: '2px', cursor: 'pointer' }}
                            onClick={() => navigate('/talent')}
                          >
                            Find talent
                          </Typography>
                          <Typography variant="caption" sx={{ position: 'relative', top: '2px' }}>
                            |
                          </Typography>
                        </>
                        <Typography
                          variant="caption"
                          sx={{ position: 'relative', top: '2px', cursor: 'pointer', paddingRight: '8px' }}
                          onClick={() => navigate('/info')}
                        >
                          Why Shoutt?
                        </Typography>
                      </Stack>
                    )}

                    {authContext.user && (
                      <>
                        <IconButton
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleMenu}
                          sx={{ padding: 0 }}
                          size="small"
                        >
                          <StyledAvatar
                            userId={authContext.user?.id}
                            displayName={`${authContext.user?.firstName} ${authContext.user?.lastName}`}
                            profileImageId={authContext.user?.profileImageId}
                            noLink
                          />
                        </IconButton>

                        <Menu
                          id="menu-appbar"
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={handleNavigationClick(`/users/${authContext.user?.id}`)}>
                            My Profile
                          </MenuItem>

                          <MenuItem onClick={handleNavigationClick('/billingaddress')}>Billing Address</MenuItem>
                          <MenuItem onClick={handleNavigationClick('/subscriptions')}>My Subscriptions</MenuItem>
                          <MenuItem onClick={handleNavigationClick('/payout')}>Payout Details / Branding</MenuItem>

                          <MenuItem onClick={handleNavigationClick('/password/change')}>Change Password</MenuItem>
                          <MenuItem color="error" onClick={handleShowDeleteProfile}>
                            Delete Profile
                          </MenuItem>
                          {hasAdminRole && <MenuItem onClick={handleNavigationClick('/admin/users')}>Vetting</MenuItem>}
                          {hasAdminRole && <MenuItem onClick={handleNavigationClick('/admin/skills')}>Skills</MenuItem>}
                          {hasAdminRole && <MenuItem onClick={handleNavigationClick('/disputes')}>Disputes</MenuItem>}
                          <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
                        </Menu>
                      </>
                    )}

                    {!authContext.user && (
                      <Stack alignItems="center" direction="row" spacing={{ xs: 1, sm: 2 }}>
                        {isMobile && (
                          <>
                            <Typography
                              variant="caption"
                              sx={{ position: 'relative', top: '2px', cursor: 'pointer' }}
                              onClick={() => navigate('/talent')}
                            >
                              Find talent
                            </Typography>
                            <Typography variant="caption" sx={{ position: 'relative', top: '2px' }}>
                              |
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{ position: 'relative', top: '2px', cursor: 'pointer' }}
                              paddingRight={'6px'}
                              onClick={() => redirectToLogin(navigate, location)}
                            >
                              Log In
                            </Typography>
                          </>
                        )}

                        {!isMobile && (
                          <Button
                            color="inherit"
                            size={isMobile ? 'small' : 'medium'}
                            sx={{
                              borderRadius: '32px',
                              minWidth: '20px',
                              p: { xs: '14px 12px 14px 0px', sm: '8px 16px' },
                            }}
                            variant="text"
                            onClick={() => redirectToLogin(navigate, location)}
                          >
                            Log In
                          </Button>
                        )}
                        <Button
                          sx={{
                            background: theme.palette.primary.main,
                            borderRadius: '32px',
                            p: { xs: '14px 12px', sm: '8px 16px' },
                          }}
                          color="primary"
                          size={isMobile ? 'small' : 'medium'}
                          variant="contained"
                          onClick={handleButtonClick('/signup')}
                        >
                          Sign Up
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                )}
              </Toolbar>
            </Container>
          </StyledAppBar>
        </HideOnScroll>
      )}
      {process.env.REACT_APP_PRIMING !== 'true' && <HeaderBanner />}

      {isMobile && (
        <BottomNavigation sx={{ zIndex: 1100 }}>
          <StyledBottomNavigationAction
            icon={location.pathname.toLowerCase() === '/' ? <HomeIcon /> : <HomeOutlinedIcon />}
            onClick={handleButtonClick('/')}
          />
          <StyledBottomNavigationAction
            icon={
              location.pathname.toLowerCase().startsWith('/users') && userId === undefined ? (
                <PersonSearchIcon />
              ) : (
                <PersonSearchOutlinedIcon />
              )
            }
            onClick={handleButtonClick('/users')}
          />

          {!authContext.loading && authContext.user && (
            <StyledBottomNavigationAction
              key="Network"
              icon={
                <Badge color="primary" badgeContent={numberOfInvitations}>
                  {location.pathname.toLowerCase().startsWith('/network') ? <PeopleIcon /> : <PeopleOutlineIcon />}
                </Badge>
              }
              onClick={handleButtonClick('/network/connections')}
            />
          )}
          {!authContext.loading && authContext.user && numberOfConnections > 0 && (
            <StyledBottomNavigationAction
              icon={
                <Badge color="primary" badgeContent={numberOfUnreadMessages}>
                  {location.pathname.toLowerCase().startsWith('/workroom') ? <MailIcon /> : <MailOutlineIcon />}
                </Badge>
              }
              onClick={handleButtonClick('/workroom')}
            />
          )}
          {!authContext.loading && authContext.user && numberOfConnections > 0 && (
            <StyledBottomNavigationAction
              key="Contracts"
              icon={
                location.pathname.toLowerCase().startsWith('/contracts') ? (
                  <AssignmentIcon />
                ) : (
                  <AssignmentOutlinedIcon />
                )
              }
              onClick={handleButtonClick('/contracts')}
            />
          )}
          {!authContext.user && (
            <StyledBottomNavigationAction icon={<LoginIcon />} onClick={() => redirectToLogin(navigate, location)} />
          )}
        </BottomNavigation>
      )}

      <DeleteProfileDialog
        open={isDeleteProfileVisible}
        onClose={handleCloseDeleteProfile}
        onLogout={handleLogoutClick}
      />
    </>
  );
};

export default Header;
