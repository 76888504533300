import growNetworkImage1 from '../../../assets/images/phoneSet1.webp';
import userAvatar1 from '../../../assets/images/user/avatar1.webp';
import userAvatar2 from '../../../assets/images/user/avatar10.webp';
import userAvatar3 from '../../../assets/images/user/avatar3.webp';
import userAvatar4 from '../../../assets/images/user/avatar4.webp';
import userAvatar5 from '../../../assets/images/user/avatar5.webp';
import userAvatar6 from '../../../assets/images/user/avatar6.webp';
import userAvatar7 from '../../../assets/images/user/avatar7.webp';
import userAvatar8 from '../../../assets/images/user/avatar8.webp';
import userAvatar9 from '../../../assets/images/user/avatar9.webp';
import userAvatar11 from '../../../assets/images/user/avatar12.webp';
import userAvatar12 from '../../../assets/images/user/avatar13.webp';
import userAvatar13 from '../../../assets/images/user/avatar14.webp';
import { FaqOption } from 'global/interfaces/faq';
import { OutlinedNibIcon } from 'components/icon/OutlinedNibIcon';
import connectImage from '../../../assets/images/connect.webp';
import collaborateImage from '../../../assets/images/collaborate.webp';
import streamlineWorkImage from '../../../assets/images/streamlineWork.webp';
import categoryLookups from '../../../json/CategoryLookups.json';

export const faqOptions: FaqOption[] = [
  {
    id: '01',
    question: 'What makes Shoutt different from other freelancer platforms?',
    answer:
      'We’re on a mission to flip the script on freelance platforms. We’re all about high-quality connections, empowering freelancers, and connecting talent with clients—without the hassle. We vet all freelancers on our marketplace, and we don’t charge any commissions on transactions. Our goal is to help freelancers to scale their businesses.',
  },
  {
    id: '02',
    question: 'Can you help me to find new clients?',
    answer:
      'Yes! Once your profile has been approved you will be listed on our marketplace. New clients can then find your profile and contact you for quotes. No bidding is required. We also recommend your profile and invite you to new opportunities.',
  },
  {
    id: '03',
    question: 'What type of services can I offer on Shoutt’s marketplace?',
    answer:
      'Most of our freelancers currently offer digital services in creative or technical fields. This includes web design, social media, video editing, marketing, development, engineering, data, and more.',
  },
  {
    id: '04',
    question: 'How does payment protection work? ',
    answer:
      'If the client disappears or refuses to approve work when you have fulfilled the terms of the contract, then we will release your payment to you. Please note that whilst you are free to go off-platform at any time, all communications must be done via our workspace to utilise our payment protection services.',
  },
  {
    id: '05',
    question: 'How do contracts work?',
    answer:
      'Just set up a new contract with your client according to your preferences, an invoice is automatically issued and they pay upfront to activate the contract.\n\nFor fixed price contracts, your funds are sent directly to your bank once the work is completed and approved by your client according to the mutually agreed terms.\n\nFor retainers, payments are automatically released on the agreed schedule. You can set a release schedule that you’re both comfortable with such as the middle or end of each period.\n\nYour clients can pay instantly on platform or via a downloadable invoice. They can pay with all leading debit / credit cards, BACS, ACH, bank transfer, direct debit, Link and Google / Apple Pay.',
  },
  {
    id: '06',
    question: 'Do you support sole proprietors and registered companies? ',
    answer: 'Yes, both independent traders and limited companies are welcome on Shoutt!',
  },
  {
    id: '07',
    question: 'How can I get more reviews and boost my profile?',
    answer:
      'Kick start your profile by using our secure payment protection tools to invoice any of your non-Shoutt clients. You’ll gain reviews from clients you already trust, boost your profile and gain even more visibility.\n\nSimply invite your own clients from your dashboard. It takes 1-2 minutes for them to create a free account, and then you can create contracts with them.',
  },
  {
    id: '08',
    question: 'How can I subcontract work out?',
    answer:
      'A Shoutt account allows you to buy and sell from one place. You can hire other freelancers to handle increased workloads whilst retaining control of the client relationship. You can also create a Team, and add your existing colleagues and new freelancers to it, in a similar way to how channels work on Slack.',
  },
  {
    id: '09',
    question: 'Can I customise the invoices?',
    answer:
      'Yes, you can add your own logo and business / contact details via a premium account. Charges will appear on your clients’ statements as the business name that you set. ',
  },
  {
    id: '10',
    question: 'What countries do you support?',
    answer:
      'Shoutt supports seller payouts in 40+ countries. If you see your country in the dropdown during the sign-up process, then you’re covered. If your country does not appear on the list, unfortunately you will not be able to proceed. We’re constantly adding more to our list, so please do check back in the future.',
  },
];

export const billingFaqOptions: FaqOption[] = [
  {
    id: '01',
    question: 'How does payment protection work? ',
    answer:
      'If the client disappears or refuses to approve work when you have fulfilled the terms of the contract, then we will release your payment to you. Please note that whilst you are free to go off-platform at any time, all communications must be done via our workspace to utilise our payment protection services.',
  },
  {
    id: '02',
    question: 'How do contracts work?',
    answer:
      'Just set up a new contract with your client according to your preferences, an invoice is automatically issued and they pay upfront to activate the contract.\n\nFor fixed price contracts, your funds are sent directly to your bank once the work is completed and approved by your client according to the mutually agreed terms.\n\nFor retainers, payments are automatically released on the agreed schedule. You can set a release schedule that you’re both comfortable with such as the middle or end of each period.\n\nYour clients can pay instantly on platform or via a downloadable invoice. They can pay with all leading debit / credit cards, BACS, ACH, bank transfer, direct debit, Link and Google / Apple Pay.',
  },
  {
    id: '03',
    question: 'Do you support sole proprietors and registered companies? ',
    answer: 'Yes, both independent traders and limited companies are welcome on Shoutt!',
  },
  {
    id: '04',
    question: 'How can I invite clients to boost my profile?',
    answer:
      'Kick start your profile by using our secure payment protection tools to invoice any of your non-Shoutt clients. You’ll gain reviews from clients you already trust, boost your profile and gain even more visibility.\n\nSimply invite your own clients from your dashboard. It takes 1-2 minutes for them to create a free account, and then you can create contracts with them.',
  },
  {
    id: '05',
    question: 'How can I subcontract work out?',
    answer:
      'A Shoutt account allows you to buy and sell from one place. You can hire other freelancers to handle increased workloads whilst retaining control of the client relationship. You can also create a Team, and add your existing colleagues and new freelancers to it, in a similar way to how channels work on Slack.',
  },
  {
    id: '06',
    question: 'Can I customise the invoices?',
    answer:
      'Yes, you can add your own logo and business / contact details via a premium account. Charges will appear on your clients’ statements as the business name that you set. ',
  },
  {
    id: '07',
    question: 'Who do I contact for help with billing?',
    answer: 'For any billing queries please contact support@shoutt.co.',
  },
];

export const whatYouGetOptions = [
  {
    id: '01',
    title: 'It’s who you know',
    description:
      'Make valuable connections with brands, freelancers and creators to exchange ideas, support each other and boost your income.',
    image: growNetworkImage1,
  },
  {
    id: '02',
    title: 'No bidding, no hassle',
    description:
      'As your network grows, so do you. You’ll get more smart recommendations when mutual connections need your skills.',
    image: growNetworkImage1,
  },
  {
    id: '03',
    title: 'Simple & rewarding workflow',
    description:
      'We’ve revolutionised the entire process with chat, files and payments in one place. No more juggling multiple tabs or searching for scattered info.',
    image: growNetworkImage1,
  },
];

export const highlightSkills = categoryLookups.map(category => ({
  id: category.id,
  label: category.label,
  icon: OutlinedNibIcon,
}));

export const clients = [
  {
    id: '01',
    image: userAvatar1,
  },
  {
    id: '02',
    image: userAvatar2,
  },
  {
    id: '03',
    image: userAvatar3,
  },
  {
    id: '04',
    image: userAvatar4,
  },
  {
    id: '05',
    image: userAvatar5,
  },
  {
    id: '06',
    image: userAvatar13,
  },
  {
    id: '07',
    image: userAvatar6,
  },
  {
    id: '08',
    image: userAvatar11,
  },
  {
    id: '09',
    image: userAvatar7,
  },
  {
    id: '10',
    image: userAvatar8,
  },
  {
    id: '11',
    image: userAvatar9,
  },
  {
    id: '12',
    image: userAvatar12,
  },
];

export const workingSteps = [
  { label: 'Find and connect with skilled freelancers', image: connectImage },
  { label: 'Collaborate on your content projects', image: collaborateImage },
  { label: 'Build your team and streamline work', image: streamlineWorkImage },
];
