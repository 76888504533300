import { useFormik } from 'formik';
import { ISignUpForm } from 'global/interfaces/signup';
import { editSignUpValidation } from 'global/validations/signup';
import { useEffect, useState } from 'react';
import { AccountVerification } from './sections/AccountVerification';
import { SignupForm } from './sections/SignupForm';
import { verifyEmail } from 'services/userService';
import { AccountTypeOptions } from 'global/enums/accountTypeOptions';
import { Box } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTitle } from 'utils/router';

const SignupRenderer: React.FC = () => {
  const [isVerify, setVerify] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<boolean>(false);
  const [isSignUpSubmitting, setIsSignUpSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectToSellerId, setRedirectToSellerId] = useState<string | undefined>(undefined);
  const [searchParams] = useSearchParams();

  useTitle('Sign Up');

  useEffect(() => {
    if (location.pathname == '/signup/details') {
      if (location.state?.redirectTo?.state?.sellerId) {
        setRedirectToSellerId(location.state?.redirectTo?.state?.sellerId);
      } else {
        setRedirectToSellerId(undefined);
      }
    }
  }, [location.pathname]);

  const form = useFormik<ISignUpForm>({
    initialValues: {
      firstName: '',
      lastName: '',
      country: '',
      email: '',
      password: '',
      confirmPassword: '',
      confirmationCode: 0,
      accountType: AccountTypeOptions.Buyer,
      referralID: localStorage.getItem('ReferralID'),
      sourceID: localStorage.getItem('SourceID'),
    },
    validationSchema: editSignUpValidation,
    onSubmit: async (values: ISignUpForm) => {
      setIsSignUpSubmitting(true);
      verifyEmail(values.email)
        .then(() => {
          setVerify(true);
          navigate(`/signup/verification?accountType=${searchParams.get('accountType')}`);
          setIsSignUpSubmitting(false);
        })
        .catch(() => {
          setCodeError(true);
          setIsSignUpSubmitting(false);
        });
    },
  });

  const handleBackClick = () => {
    setVerify(false);
  };

  return (
    <>
      {isVerify && (
        <AccountVerification
          form={form}
          codeError={codeError}
          setCodeError={setCodeError}
          handleBackClick={handleBackClick}
          redirectToSellerId={redirectToSellerId}
        />
      )}
      <Box style={{ display: !isVerify ? 'block' : 'none' }}>
        <SignupForm form={form} isSignUpSubmitting={isSignUpSubmitting} />
      </Box>
    </>
  );
};

export default SignupRenderer;
