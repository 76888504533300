import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import heroAnimationData from 'assets/images/lps/hirecreative.webp';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { CheckIcon } from 'components/icon/CheckIcon';
import { desktopHeaderHeight, mobileHeaderHeight } from 'global/Constants/HeaderFooterConstants';
import UserSearch from 'components/common/Search/UserSearch';

const BannerBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFEFC2',
  marginTop: `${desktopHeaderHeight}px`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    marginTop: `0px`,
  },
}));

const StyledHeroImage = styled('img')(({ theme }) => ({
  maxHeight: '320px',

  [theme.breakpoints.down('md')]: {
    maxHeight: '170px',
  },
}));

const BannerTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.black[500],
  maxWidth: '700px',
  textAlign: 'start',
  lineHeight: '120%',
}));

const FeatureText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '28px',
  fontWeight: '600',
  color: theme.palette.black[500],
  letterSpacing: '-0.5px',

  [theme.breakpoints.down('lg')]: {
    fontSize: '16px',
    lineHeight: '26px',
  },

  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    lineHeight: '18.054px',
    letterSpacing: '-0.322px',
  },
}));

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  height: '32px',
  width: '32px',
  display: 'none',

  [theme.breakpoints.down('md')]: {
    height: '20px',
    width: '20px',
  },
}));

const FeatureContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: 'white',
  gap: '10px',
  padding: '8px',
  border: '1px solid #231A14',
  borderRadius: '8px',

  boxShadow: '5px 5px 0px 0px #0000001A',

  [theme.breakpoints.down('md')]: {
    gap: '8px',
    padding: '4px',
  },
}));

const HeaderBanner = () => {
  const location = useLocation();
  const showHeaderGraphic = location.pathname.toLowerCase() === '/talent';
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  return (
    <>
      {showHeaderGraphic && (
        <>
          {isMobileScreen && (
            <Box mb="12px" mt={`${mobileHeaderHeight + 12}px`} paddingX="16px">
              <UserSearch />
            </Box>
          )}
          <BannerBox>
            <Container maxWidth="lg">
              <Stack
                direction={isMobileScreen ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={isMobileScreen ? 'flex-start' : 'center'}
                py={{ xs: '32px' }}
              >
                {isMobileScreen && (
                  <BannerTitle variant="h4" fontSize={{ xs: '28px', md: '36px', lg: '50px' }}>
                    Handpicked freelancers for time-strapped founders
                  </BannerTitle>
                )}
                {isMobileScreen && (
                  <Typography
                    variant="body1"
                    fontSize={{ xs: '14px', md: '16px' }}
                    mt={2}
                    maxWidth="700px"
                    color={theme.palette.common.black}
                  >
                    Reach out to our network of vetted freelancers to help with your digital projects. Don&apos;t have
                    time to browse? You can use our managed concierge service to{' '}
                    <span
                      style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
                      onClick={() => navigate('/concierge')}
                    >
                      get 3 proposals
                    </span>
                    .
                  </Typography>
                )}

                <Stack
                  direction={isMobileScreen ? 'row' : 'column'}
                  justifyContent={isMobileScreen ? 'space-between' : 'flex-start'}
                  width="100%"
                  alignItems={{ xs: 'flex-end', md: 'flex-start' }}
                >
                  {!isMobileScreen && (
                    <BannerTitle variant="h3" fontSize={{ xs: '28px', md: '36px', lg: '50px' }}>
                      Handpicked freelancers for time-strapped founders
                    </BannerTitle>
                  )}
                  {!isMobileScreen && (
                    <Typography
                      variant="body1"
                      fontSize={{ xs: '14px', md: '16px' }}
                      mt={2}
                      maxWidth="700px"
                      color={theme.palette.common.black}
                    >
                      Reach out to our network of vetted freelancers to help with your digital projects. Don&apos;t have
                      time to browse? You can use our managed concierge service to{' '}
                      <span
                        style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
                        onClick={() => navigate('/concierge')}
                      >
                        get 3 proposals
                      </span>
                      .
                    </Typography>
                  )}
                  <Stack
                    mt="32px"
                    mb={{ xs: '32px', md: 0 }}
                    direction={isMobileScreen ? 'column' : 'row'}
                    gap={isMobileScreen ? '14px' : '18px'}
                  >
                    <FeatureContainer>
                      <StyledCheckIcon />
                      <FeatureText>Vetted Talent Only</FeatureText>
                    </FeatureContainer>
                    <FeatureContainer>
                      <StyledCheckIcon />
                      <FeatureText>Small or Big Projects</FeatureText>
                    </FeatureContainer>
                    <FeatureContainer>
                      <StyledCheckIcon />
                      <FeatureText>100% Commission Free</FeatureText>
                    </FeatureContainer>
                  </Stack>

                  {isMobileScreen && <StyledHeroImage src={heroAnimationData} />}
                </Stack>

                {!isMobileScreen && <StyledHeroImage src={heroAnimationData} />}
              </Stack>
            </Container>
          </BannerBox>
        </>
      )}
    </>
  );
};

export default HeaderBanner;
