import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { DetailContainer, CenteredBox } from 'components/terms/Containers';
import Template from 'components/terms/Template';
import { useTitle } from 'utils/router';

interface MobileProps {
  isMobile: boolean;
  isTablet: boolean;
}

const BannerContent = ({ isMobile, isTablet }: MobileProps): JSX.Element => (
  <CenteredBox>
    <Typography variant={isTablet ? (isMobile ? 'h5' : 'h3') : 'h2'}>About Us</Typography>
  </CenteredBox>
);

export default function About(): JSX.Element {
  useTitle('About Us');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <>
      <Template
        backgroundColor="#D2F0E0"
        bannerContent={<BannerContent isMobile={isMobileScreen} isTablet={isTabletScreen} />}
      >
        <DetailContainer>
          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>
            Connecting busy founders and talented freelancers{' '}
          </Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            Shoutt connects founders and freelancers, helping everyone to build their businesses by making it easy to
            make connections, build relationships and get quality work done. Our team of entrepreneurs and engineers
            from the UK and beyond grew frustrated with generalist platforms. We knew there had to be a better way—so we
            built it!
          </Typography>
          <Typography variant={isMobileScreen ? 'h6' : 'h5'} mt={3}>
            Quality clients, no commissions
          </Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            Freelancing isn’t just about chasing gigs; it’s about building a sustainable business and a network of
            clients that appreciate you. Here’s what different about us:
            <br />
            <ol style={{ marginTop: '24px', marginBottom: '24px' }}>
              <li>We don&apos;t charge any commissions, keep 100% of your hard earned money</li>
              <li>
                We vet all talent to ensure the marketplace is all about quality, not quantity. This in turn helps
                attract quality clients, with no races to the bottom to be found!
              </li>
              <li>
                Already working with clients? Bring them over to Shoutt. Our secure payment system means no more late
                payments or chasing invoices. Plus, every project adds a review to boost your profile, making you stand
                out even more.
              </li>
              <li>
                No bidding wars involved, just create your free profile and let clients discover your work and reach out
                to you directly.
              </li>
            </ol>
            On Shoutt, everything happens in one place. Chat with clients, send invoices, sign contracts, and get
            paid—all within a single, streamlined chat.
            <br />
            <br />
            You can even subcontract work to other freelancers from the same account, which opens us new possibilities
            for scaling your business and saving precious time.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'} mt={3}>
            Your own network of talent that scales with your business
          </Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            For busy founders, finding and managing the right talent can be a time-draining challenge. Shoutt simplifies
            the process, making it easy to hire and collaborate with vetted freelancers from around the world.
            <br />
            <br />
            We screen every freelancer to ensure you’re only connecting with top-quality professionals, saving you from
            sifting through low-quality proposals and boilerplate GPT pitches.
            <br />
            <br />
            All transactions on Shoutt are protected by our secure payment system, ensuring that your funds are safe and
            your projects are delivered on time.
            <br />
            <br />
            Whether you’re hiring through our vetted marketplace or bringing in freelancers you already know, Shoutt
            helps you build a reliable, long-term network of talent that scales with your business.
            <br />
            <br />
            And if you need extra help finding the perfect fit, our concierge service is here to source the right
            freelancers for your needs.
          </Typography>
        </DetailContainer>
      </Template>
    </>
  );
}
