import { FaqOption } from 'global/interfaces/faq';
import growNetworkImage1 from '../../../assets/images/phoneSet1.webp';

import { OutlinedNibIcon } from 'components/icon/OutlinedNibIcon';
import inspiringWork1 from '../../../assets/images/inspiringWorks/Illustration.webp';
import inspiringWork2 from '../../../assets/images/inspiringWorks/copywriter.webp';
import inspiringWork3 from '../../../assets/images/inspiringWorks/graphicDesign.webp';
import inspiringWork4 from '../../../assets/images/inspiringWorks/squarefoot.webp';
import inspiringWork5 from '../../../assets/images/inspiringWorks/yap.gif';
import inspiringWork6 from '../../../assets/images/inspiringWorks/shapesLottie.gif';
import categoryLookups from '../../../json/CategoryLookups.json';

export const whatYouGetOptions = [
  {
    id: '01',
    title: 'Make great content',
    description:
      'With a lot less hassle! Have your own team of creatives on the books, so you can focus on the bigger picture.',
    image: growNetworkImage1,
  },
  {
    id: '01',
    title: 'Build relationships',
    description:
      "Your hired freelancers are more than just collaborators - they're connections! Keep the conversation & build lasting relationships.",
    image: growNetworkImage1,
  },
  {
    id: '01',
    title: 'Work smoothly',
    description:
      'Work faster with chat, files and payments in one place. No more juggling multiple tabs or searching for scattered info.',
    image: growNetworkImage1,
  },
];

export const talentCategories = categoryLookups.map(category => ({
  id: category.id,
  label: category.label,
  icon: OutlinedNibIcon,
}));

export const faqOptions: FaqOption[] = [
  {
    id: '01',
    question: 'What makes Shoutt different from other freelancer platforms?',
    answer:
      'Shoutt is designed for businesses who value quality connections over quick gigs. It’s about creating a network that works for you—efficiently, transparently, and with no hidden fees.\n\nWe vet all freelancers on our marketplace to save you having to sift through tonnes of proposals / profiles, and we don’t charge any commissions on transactions.\n\nYou can also use our payment protection tools to securely pay any freelancer, independently of our marketplace.',
  },
  {
    id: '02',
    question: 'How does payment protection work?',
    answer:
      'If the freelancer disappears or does not fulfil the terms of their contract, we will refund your payment. Please note that (1) we are unable to make subjective decisions based on the quality of work and (2) whilst you are free to go off-platform at any time, all communications must be done via our workspace to utilise our payment protection services.',
  },
  {
    id: '03',
    question: 'How do contracts work?',
    answer:
      'Once you’ve made payment, the contract is activated and we hold onto your funds until the work has been completed.\n\nFor fixed price contracts, your funds are sent directly to the freelancers bank once the work is completed and approved by you according to the mutually agreed terms.\n\nFor retainers, payments are automatically released on the agreed schedule. You can also set a release schedule that you’re both comfortable with such as the middle or end of each period.\n\nYou can pay instantly on the platform or via a downloadable invoice. We accept a wide range of payment methods including debit / credit cards, BACS, ACH, bank transfer, direct debit, Link and Google / Apple Pay. ',
  },
  {
    id: '04',
    question: 'How do you vet the freelancers on your marketplace?',
    answer:
      'To help you avoid spending hours sifting through profiles or proposals, we vet all of the freelancers listed on our marketplace to ensure they meet our quality standards. This includes checking their bio, work history, external links and more - through a blend of AI and human checks. ',
  },
  {
    id: '05',
    question: 'What type of services can I find on Shoutt?',
    answer:
      'You can find a wide range of digital services across creative and technical fields, including design, marketing, video, social media and development. Or you can bring your existing freelancers over and manage them all in one place. ',
  },
  {
    id: '06',
    question: 'What are Teams?',
    answer:
      'Teams allow you to hire new freelancers, bring your existing ones over, and get them all into one place where they can chat, exchange files and collaborate on your projects easily. It works similarly to channels on Slack, with the added bonus of being able to create contracts, find new talent and make secure escrow payments directly within the chat. ',
  },
  {
    id: '07',
    question: 'How do I bring my existing freelancers?',
    answer:
      'You can hit the send invite button from your dashboard and they will receive a personalised invitation. It only takes a few minutes for them to setup an account and add their bank details. You can then create contracts and collaborate with them. ',
  },
  {
    id: '08',
    question: 'What are the licence terms of assets (i.e. who owns them)?',
    answer:
      'All work done on the platform is under a standard ‘work for hire’ model. This means that all IP and rights are transferred to the buyer, as soon as payment is made. Customisable licence terms are coming soon.',
  },
];

export const billingFaqOptions: FaqOption[] = [
  {
    id: '01',
    question: 'How does payment protection work?',
    answer:
      'If the freelancer disappears or does not fulfil the terms of their contract, we will refund your payment. Please note that (1) we are unable to make subjective decisions based on the quality of work and (2) whilst you are free to go off-platform at any time, all communications must be done via our workspace to utilise our payment protection services.',
  },
  {
    id: '02',
    question: 'How do contracts work?',
    answer:
      'Once you’ve made payment, the contract is activated and we hold onto your funds until the work has been completed.\n\nFor fixed price contracts, your funds are sent directly to the freelancers bank once the work is completed and approved by you according to the mutually agreed terms.\n\nFor retainers, payments are automatically released on the agreed schedule. You can also set a release schedule that you’re both comfortable with such as the middle or end of each period.\n\nYou can pay instantly on the platform or via a downloadable invoice. We accept a wide range of payment methods including debit / credit cards, BACS, ACH, bank transfer, direct debit, Link and Google / Apple Pay. ',
  },
  {
    id: '03',
    question: 'How do you vet the freelancers on your marketplace?',
    answer:
      'To help you avoid spending hours sifting through profiles or proposals, we vet all of the freelancers listed on our marketplace to ensure they meet our quality standards. This includes checking their bio, work history, external links and more - through a blend of AI and human checks. ',
  },

  {
    id: '04',
    question: 'How do I invite my existing freelancers?',
    answer:
      'You can hit the send invite button from your dashboard and they will receive a personalised invitation. It only takes a few minutes for them to setup an account and add their bank details. You can then create contracts and collaborate with them. ',
  },
  {
    id: '05',
    question: 'Who do I contact for help with billing?',
    answer: 'For any billing queries please contact support@shoutt.co.',
  },
];

export const inspiringWorks = [
  {
    image: inspiringWork5,
    user: { name: 'Joe', role: 'Motion design' },
  },
  {
    image: inspiringWork6,
    user: { name: 'Stephen', role: 'Motion design' },
  },
  {
    image: inspiringWork4,
    user: { name: 'Sam', role: 'Branding' },
  },
  {
    image: inspiringWork1,
    user: { name: 'Claire', role: 'Illustration' },
  },
  {
    image: inspiringWork3,
    user: { name: 'Katie', role: 'Graphic design' },
  },

  {
    image: inspiringWork2,
    user: { name: 'James', role: 'Copywriter' },
  },
];
