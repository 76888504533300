import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import BiteSizeIndicator from './BiteSizeIndicator';
import { Marketplace, Referrals, Invite, FixedPrice, Retainers, Contracts, Escrow } from './BiteSizeContent';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { JustifiedBox } from 'components/common/StyledWorkroom/WorkroomStyles';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import { helpUrl } from 'global/constants';
import { StyledCarousel } from 'components/common/Carousel/StyledCarousel';
import { ResponsiveType } from 'react-multi-carousel';

const BiteSizeTips: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const carouselRef = useRef<any>(null);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.goToSlide(activeIndex, false);
    }
  }, [activeIndex]);

  const responsive: ResponsiveType = {
    desktop: {
      breakpoint: { max: window.screen.width, min: 1200 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1200, min: theme.breakpoints.values.md },
      items: 1,
      slidesToSlide: 1,
    },
    tablet2: {
      breakpoint: {
        max: theme.breakpoints.values.md,
        min: theme.breakpoints.values.sm,
      },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: theme.breakpoints.values.sm, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 28,
    },
  };

  return (
    <Box mt={1} width="100%">
      <JustifiedBox>
        <Stack direction="row" gap={1} ml={isDesktop ? 1.5 : 0.5}>
          <EmojiObjectsOutlinedIcon />
          <Typography variant="subtitle1" fontWeight={500}>
            Bite size tips
          </Typography>
        </Stack>
        <Stack direction="row">
          <Button
            variant="text"
            sx={{ color: theme.palette.primary.dark, mr: '-6px' }}
            endIcon={<ArrowForwardIcon />}
            onClick={() => window.open(helpUrl)}
          >
            More Help
          </Button>
        </Stack>
      </JustifiedBox>
      <StyledCarousel
        className="small-react-multiple-carousel__arrow small-poistion-react-multiple-carousel__arrow"
        sx={{
          '&.small-poistion-react-multiple-carousel__arrow .react-multiple-carousel__arrow': {
            marginRight: '0px',
            marginLeft: '0px',
            padding: '0px',
            top: '48%',
          },
        }}
        innerRef={carouselRef}
        responsive={responsive}
        infinite={false}
        autoPlay={false}
        beforeChange={nextSlide => setActiveIndex(nextSlide)}
        partialVisible={true}
        draggable={true}
        arrows={isDesktop}
      >
        <Referrals />
        <Invite />
        <Marketplace />
        <Contracts />
        <Retainers />
        <FixedPrice />
        <Escrow />
      </StyledCarousel>
      <BiteSizeIndicator activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
    </Box>
  );
};

export default BiteSizeTips;
